.content-pdf {
  position: relative;
  width: 100%;
  max-width: 700px;
  /* max-height: 80vh; */
  z-index: 1101;
  background-color: var(--color-white);
  border-radius: 8px;
  border: 1px solid var(--color-gray-5);
  padding: 40px 20px;
  /* left: 50%;
  top: 50%;å
  transform: translate(-50%, -50%); */
  overflow-x: hidden;
}
.content-pdf-lightbox {
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
}

.bioequivalent-pdf {
  position: absolute;
  width: 100%;
  max-width: 700px;
  height: 95vh;
  z-index: 2200;
  background-color: var(--color-white);
  border-radius: 8px;
  border: 1px solid var(--color-gray-5);
  padding: 40px 20px 20px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.programs-pdf {
  position: absolute;
  width: 100%;
  max-width: 700px;
  height: 95vh;
  z-index: 2200;
  background-color: var(--color-white);
  border-radius: 8px;
  border: 1px solid var(--color-gray-5);
  padding: 40px 20px 20px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.image-container-pdf {
  position: absolute;
  width: 90%;
  max-width: 700px;
  height: 95vh;
  border-radius: 8px;
  padding: 20px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.bioequivalent-pdf-lightbox {
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 2190;
}

.rpv-core__viewer--light div > div {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.prescription-min {
  height: 10.5rem;
  width: 8.5rem;
  border-radius: 8px;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-5);
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prescription-min-sm {
  height: 49px;
  width: 34px;
  overflow: hidden;
}

@media (max-width: 480px) {
  .image-container-pdf {
    position: absolute;
    width: 90%;
    max-width: 700px;
    height: 90vh;
    border-radius: 8px;
    padding: 20px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .bioequivalent-pdf {
    position: absolute;
    width: 100%;
    height: 60vh;
    z-index: 2200;
    background-color: var(--color-white);
    border-radius: 8px;
    border: 1px solid var(--color-gray-5);
    padding: 2rem 0.8rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .programs-pdf {
    position: absolute;
    width: 90%;
    height: 85vh;
    padding: 10px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .bioequivalent-pdf-lightbox {
    position: fixed;
    background: rgba(0, 0, 0, 0.2);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2190;
  }
}
