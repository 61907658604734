/* Checkbox */

.custom-checkbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: var(--font-22);
  user-select: none;
  height: 26px;
  margin: 0;
}

.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.63rem;
  width: 1.63rem;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid var(--theme-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-checkbox .checkmark i {
  font-size: var(--font-16);
  color: var(--color-white);
  display: none;
}

.custom-checkbox input:checked~.checkmark {
  background-image: var(--theme-color);
  border: 1px solid var(--theme-color);
}

.custom-checkbox input:checked~.checkmark i {
  display: block;
}

.custom-checkbox::after {
  content: '';
  position: absolute;
  display: none;
}

.custom-checkbox input:checked~.checkmark::after {
  display: block;
}

.custom-checkbox-img {
  width: 60px;
  height: 60px;
  background-color: var(--color-white);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Radio Button */

.custom-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: var(--font-22);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 20px;
}

.custom-radio:hover {
  background-color: var(--color-white);
}

.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: var(--color-white);
  border: 2px solid var(--color-primary);
  border-radius: 50%;
}

.custom-radio:hover input~.checkmark {
  background-color: var(--color-white);
}

.custom-radio input:checked~.checkmark {
  background-color: var(--color-white);
}

.custom-radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-radio input:checked~.checkmark:after {
  display: block;
}

.custom-radio .checkmark:after {
  top: 2px;
  left: 2px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--color-primary);
}