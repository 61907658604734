.box-arrow-select {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

div[class$="singleValue"]::first-letter, div[class$="-option"]::first-letter {
  text-transform: capitalize !important;
}


.title-primary--xl::first-letter {
  text-transform: capitalize !important;
}