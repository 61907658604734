/* Subscription card */
.content-pharmacies {
  overflow-x: visible;
}

.subscription-approved-icon {
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  left: 7px;
  top: -6px;
}

.approved-icon-sm {
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0;
  left: -6px;
}

.content-quotetation {
  display: flex;
  flex-direction: column;
}

.subscription-card {
  border-radius: 16px;
  background-color: var(--color-white);
  border: 1px solid #E9E6FE;
  margin-bottom: 10px;
}

.subscription-card .accordion:nth-child(+2n) {
  margin-top: -2px;
}

.subscription-card .accordion:last-child .card {
  border-bottom: 0!important;
}

.subscription-card .card:nth-child(+2n) {
  margin-top: -2px;
}

.subscription-card .accordion~.card {
  border-bottom: 0!important;
}

.subscription-card--header-img {
  width: 24px;
  height: 24px;
  border: 1px solid #C8C8C8;
  border-radius: 40px;
}

.subscription-card--header-right {
  width: auto;
}

.subscription-card--header-right .txt-title {
  font-size: var(--font-12);
  list-style: var(--font-16);
  font-family: var(--font-regular);
  color: var(--color-secondary);
}

.subscription-card--header-right .txt-price {
  font-size: var(--font-14);
  list-style: var(--font-16);
  font-family: var(--font-bold);
  color: var(--color-secondary);
}

.subscription-card--center .card {
  border-top: 1px solid var(--color-gray2-ds);
  border-bottom: 1px solid var(--color-gray2-ds);
  border-left: 0 !important;
  border-right: 0 !important;
  border-radius: 0;
}

.subscription-card--center .card-header {
  margin: 0 !important;
  margin-bottom: 1px;
  background-color: var(--color-white) !important;
  border-bottom: 0px !important;;
}

.subscription-card--center .card-header .col {
  margin: 0 !important;
  padding: 0 !important;
  cursor: pointer;
}

.subscription-card--center .card-body {
  padding: 0 1rem 1rem 1rem !important;
}

.subscription-card--bottom {
  margin-top: auto;
  display: flex;
  align-items: center;
  padding-bottom: 1.5rem;
}

.content-quotetation--item {
  width: 100%;
}

.content-quotetation--item:first-child hr {
  display: none;
}

.subscription-card .dot {
  width: 100px;
  height: 100px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: var(--color-green2);
}

.subscription-card .dot-small {
  width: 10px;
  height: 10px;
}

.content-detail {
  border-bottom: 1px solid var(--color-gray-5);
}

.content-detail:nth-last-child(1) {
  border-bottom: 0px !important;
}

.content-detail-img {
  margin-left: 22px;
}

.content-detail-subscription {
  background-color: rgba(101, 120, 255, 0.15);
  border-radius: 20px;
  padding-left: 32px;
}

.subscription-card--header-right .content-price {
  height: 26px;
  display: flex;
  align-items: center;
  padding: 7px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  padding-right: 20px;
  position: relative;
  background-color: rgba(245, 190, 14, 0.15);
}

.subscription-card--header-right .content-price i {
  font-size: var(--font-14);
  color: var(--color-highlight-ds);
  margin-right: 6px;
}

.subscription-card--center .content-icon-pharmacy i {
  font-size: var(--font-14);
  color: var(--color-highlight-ds);
}

.pharmacy-flag-subscription {
  width: 30px;
  height: 58px;
  font-size: var(--font-58);
  position: absolute;
  left: 12px;
  top: -3px;
  color: var(--color-highlight-ds)
}

.container-percentage-change {
  border: 1px solid var(--color-gray2-ds);
  border-radius: 8px;
  width: 12.5rem;
  position: relative;
  justify-content: flex-end;
  height: 2.375rem;
  align-items: center;
}

.container-percentage-change--left {
  height: 2.375rem;
  display: flex;
  align-items: center;
}

.percentage-change--left-content {
  z-index: 99;
  position: absolute;
  left: 8px;
}

.percentage-change--left-base {
  position: absolute;
  left: -1px;
  top: -1px;
  height: 2.375rem;
}

.container-percentage-change--right {
  width: 7.1875rem;
  text-align: center;
}

@media (min-width: 768px) {
  .content-quotetation .subscription-card {
    margin-right: 20px;
  }
  .content-pharmacies .subscription-card {
    width: 288px;
    display: block;
    margin-right: 0.5rem;
  
  }
  .cards-best-price .subscription-card {
    margin-top: 0 !important;
  }
  .cards-best-price .slick-slide:first-child .subscription-card {
    margin-top: 0 !important;
  }
  .cards-best-price .subscription-card {
    margin-top: 0 !important;
  }
  .cards-best-price .subscription-card:first-child {
    margin-top: 0 !important;
  }
  .cards-best-price .slick-slide:first-child .subscription-card {
    margin-top: 0 !important;
  }
  .cards-best-price .slick-slide .subscription-card {
    margin-top: 45px !important;
  }
  .cards-best-price .subscription-card:nth-child(2) {
    margin-top: 45px !important;
  }
}

@media (min-width: 768px) and ( max-width: 1023px) {
  .cards-best-price .subscription-card:first-child {
    margin-top: 0 !important;
  }
}

@media (min-width: 992px) {
  .cards-best-price .subscription-card:nth-child(2),
  .cards-best-price .subscription-card:nth-child(3) {
    margin-top: 45px !important;
  }
  .content-pharmacies .subscription-card {
    width: 288px;
  }
}

@media (max-width: 767px)  {
  /* .content-pharmacies .subscription-card {
    width: 288px;
    display: block;
    margin-right: 0.5rem;
  } */
  /* .content-pharmacies {
    display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  } */
}