.section-benefits-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  align-items: stretch;
  max-width: 1100px;
  margin: 0 auto;
}

.section-benefits-cards div {
  width: 100%;
  max-width: 250px;
}