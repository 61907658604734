.card-result-pharmacy {
    background: var(--color-white);
    border-radius: 8px;
    border: 1px solid var(--color-gray-5);
    margin-bottom: 8px;
}

.header-card-result-pharmacy {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid var(--color-gray-5);
}

.content-card-result-pharmacy {
    padding: 12px 10px;
}

.card-result-pharmacy-img {
    width: 34px;
    height: 34px;
    border-radius: 100%;
    object-fit: contain;
}

.content-card-result-pharmacy {
    display: flex;
    flex-direction: column;
}

@media (min-width: 768px) {
    .content-card-result-pharmacy {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .content-card-result-pharmacy .card-result-pharmacy {
        width: calc(50% - 8px);
        margin-right: 8px;
    }
}

@media (min-width: 992px) {
    .content-card-result-pharmacy .card-result-pharmacy {
        width: calc(33.3% - 8px);
        margin-right: 8px;
    }
}