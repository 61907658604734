.benefit-card h2 {
  font-size: 18px;
  font-weight: 600;
  color: #282e55;
}

.benefit-card p {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  color: #282e55;
}

.benefit-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 20px;
  /* max-width: 270px; */
}

.benefit-card .image {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
}

.benefit-card .image img {
  height: 100%;
  max-height: 150px;
  object-fit: contain;
  width: 100%;
}

.benefit-card .discount {
  align-items: center;
  background-color: #6578ff;
  border-radius: 6px;
  color: white;
  display: flex;
  flex-direction: column;
  height: 33%;
  justify-content: center;
  max-height: 130px;
  text-align: center;
  padding: 8px;
  position: relative;
  bottom: 0px;
}

.benefit-card .discount p {
  display: block;
  font-size: 40px;
  font-weight: 300;
  line-height: 10px;
  margin: 20px 0;
  color: white;
}

.benefit-card .discount-info {
  align-items: center;
  display: flex;
  justify-content: center;
}

.benefit-card .discount-info .number {
  font-size: 48px;
  font-weight: 300;
  width: auto;
}

.benefit-card .discount-info .percentage {
  margin-bottom: 5px;
  width: auto;
}

.benefit-card .discount-info .percentage p {
  font-size: 24px;
  font-weight: 200;
  line-height: 0;
  text-align: right;
}