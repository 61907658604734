.chooseFileButton {
  background-color: var(--theme-color)!important;
  /* border-radius: 4px !important; */
}

.chooseFileButton::before {
  background-image: url("../assets/img/ic_add.svg")!important;
  background-size: 0.6875rem 0.6875rem!important;
  width: 0.6875rem!important;
  height: 0.6875rem!important;
  content:""!important;
  display: inline-block;
  margin-right: 0.5rem;
  position: relative;
  top: 1.5px;
}

.uploadPicturesWrapper {
  overflow-y: auto!important;
  overflow-x: hidden!important;
  max-height: 15vh!important;
  margin-top: 0.5rem;
}

.uploadPicturesWrapper div {
  justify-content: start!important;
}

.uploadPictureContainer {
  margin: 0!important;
  margin-top: 1rem!important;
  margin-right: 1.5rem!important;
  padding: 0!important;
  width: 2.125rem!important;
  height: 3.0625rem!important;
  box-shadow: none!important;
  border: none!important;
  background: #C4C4C4!important;
}

.deleteImage {
  background-image: url("../assets/img/remover.svg")!important;
  left: 1.375rem!important;
  top: -0.75rem!important;
  width: 1.5rem!important;
  height: 1.5rem!important;
  font-size: 0!important;
}
