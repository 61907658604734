.wrapper-legal {
  /* margin-top: 20px; */
}

.content-legal {
  background-color: transparent;
  margin-left: -19px;
  margin-right: -19px;
  list-style: none;
  padding: 0;
}

.content-legal-item {
  padding: 10px 16px;
  border-bottom: 1px solid var(--color-gray2-ds);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 16px;
  margin-right: 16px;
  cursor: pointer;
}

.content-legal-item:last-child {
  border-bottom: 0;
}

.content-legal-left {
  display: flex;
  align-items: center;
}

.content-legal-image {
  width: 34px;
  height: 34px;
  border-radius: 18px;
  border: 1px solid #E1E1E1;
  margin-right: 17px;
}

.content-legal-right i {
  font-size: var(--font-12);
}