.badges-refundable {
    background-color: var(--color-primary-ds);
    height: 1.3125rem;
    display: flex;
    align-items: center;
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
    z-index: 99;
    font-size: var(--font-12);
    color: var(--color-white);
    padding: 0 0.5rem;
    border-radius: 0.5rem 0.625rem 0.625rem 0;
    position: absolute;
    top: 0;
    left: 0;
    min-width: 7.25rem;
  }