@import 'bootstrap/dist/css/bootstrap.min.css';
@import '../src/assets/icons/style.css';
@font-face {
  font-family: 'Nunito-Regular';
  src: local('Nunito-Regular'), url(./assets/fonts/Nunito-Regular.ttf);
}

@font-face {
  font-family: 'Nunito-Bold';
  src: local('Nunito-Bold'), url(./assets/fonts/Nunito-SemiBold.ttf);
}

@font-face {
  font-family: 'Nunito-Light';
  src: local('Nunito-Light'), url(./assets/fonts/Nunito-Light.ttf);
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'), url(./assets/fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: local('Montserrat-Bold'), url(./assets/fonts/Montserrat-Bold.ttf);
}

@font-face {
  font-family: 'Montserrat-Light';
  src: local('Montserrat-Light'), url(./assets/fonts/Montserrat-Light.ttf);
}

.rpv-core__text-layer {
  display: none !important;
}