/* HELPERS */
.t-0 {
  top: 0;
}

.h--36 {
  height: 36px;
}

.m-0 {
  margin: 0;
}
.m-0-auto {
  margin: 0 auto;
}

.m-90p {
  width: 90%;
}
.w-250 {
  width: 250px !important;
}

.w-230 {
  width: 230px !important;
}

.w-160 {
  width: 160px !important;
}

.w-162 {
  width: 162px !important;
}

.w-165 {
  width: 165px !important;
}

.w-190 {
  width: 190px !important;
}

.mh-95 {
  min-height: 95px;
}

.mw-40 {
  min-width: 40px;
}
.h-34 {
  height: 34px !important;
}

.mw-100 {
  min-width: 100px;
}

.mw-80 {
  min-width: 80px;
}

.mw-60 {
  min-width: 60px;
}

.mw-90 {
  min-width: 90px;
}

.mw-115 {
  min-width: 115px;
}
.mw-100 {
  min-width: 100px;
}
.mw-180 {
  min-width: 180px;
}

.mw-280 {
  min-width: 280px;
}

.maxw-80 {
  max-width: 80px;
}

.maxw-100 {
  max-width: 100px;
}

.maxw-125 {
  max-width: 125px;
}

.maxw-135 {
  max-width: 135px;
}

.maxw-180 {
  max-width: 180px;
}

.maxw-200 {
  max-width: 200px;
}

.maxw-214 {
  max-width: 214px;
}

.maxw-218 {
  max-width: 218px;
}

.maxw-250 {
  max-width: 250px;
}

.maxw-290 {
  max-width: 290px;
}

.maxw-300 {
  max-width: 300px;
}

.maxw-320 {
  max-width: 320px;
}

.maxw-325 {
  max-width: 325px;
}

.maxw-380 {
  max-width: 380px;
}
.maxw-400 {
  max-width: 400px;
}

.maxw-436 {
  max-width: 436px;
}

.maxw-500 {
  max-width: 500px;
}

.maxw-584 {
  max-width: 584px;
}

.maxw-600 {
  max-width: 600px;
}

.maxw-620 {
  max-width: 620px;
}

.maxw-800 {
  max-width: 800px;
}

.maxw-900 {
  max-width: 900px;
}

.maxw-90p {
  max-width: 90%;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.ml-00 {
  margin-left: 0 !important;
}

.ml-04 {
  margin-left: 4px;
}

.ml-14 {
  margin-left: 14px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-40 {
  margin-right: 40px;
}

.ml-40 {
  margin-left: 2.5rem;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-zero {
  margin-top: 0 !important;
}

.mt-12 {
  margin-top: 12px;
}

.pr-50 {
  padding-right: 50px;
}

.px-18 {
  padding-left: 18px;
  padding-right: 18px;
}

.px-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.py-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.hpt-8 {
  padding-top: 8px !important;
}

.hpt-16 {
  padding-top: 16px !important;
}

.hover-edit:hover .hover-edit--item {
  opacity: 1;
}

.hover-edit--item {
  opacity: 0;
  transition: all 0.3s linear;
}

.line-14 {
  line-height: 14px;
}

.line-16 {
  line-height: 16px;
}
.line-26 {
  line-height: 26px;
}
.separation-blue {
  background-color: var(--color-primary);
  margin: 10px -20px;
  width: auto;
}

.hr-lilac {
  background-color: #e9e6fe;
}

.icon-x2 {
  font-size: var(--font-22);
}

.icon-reverse {
  transform: rotate(180deg);
}

.break-word {
  word-break: break-word;
}

.icon-reverse {
  transform: rotate(180deg);
}

.white-space-nowrap {
  white-space: nowrap;
}

b {
  font-family: var(--font-bold);
  font-weight: 700;
}

.header-in-modal {
  width: calc(100% + 38px);
}

.incorrect-text {
  text-decoration: line-through;
  color: var(--color-gray-3);
}

.c-pointer {
  cursor: pointer;
}

.image-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-grow-1 {
  flex-grow: 1;
}
.flex-1 {
  flex:1;
}

.link-mail {
  color: var(--color-secondary);
}

.color-lilac .link-mail {
  color: var(--color-primary);
}

.link-client {
  color: var(--color-secondary);
}

.color-theme .link-client {
  color: var(--theme-color) !important;
}

.color-gray-1 {
  color: var(--color-gray1-ds);
}

.color-highlight {
  color: var(--color-highlight-ds);
}

.link-client:hover {
  color: var(--theme-color) !important;
}

.check-item {
  width: 24px;
  margin-right: 6px;
}

.check-item img {
  width: 100%;
  aspect-ratio: 3/2;
  object-fit: contain;
}

hr {
  border-top-color: var(--color-light-ds);
}

.hr-separation {
  border-top-color: 1px solid var(--color-light-ds);
  margin-top: -1px;
}

.hr--gray {
  border-top: 1px solid var(--color-gray-5);
  margin: 0;
}

.hr--gray-4 {
  border-top: 1px solid var(--color-gray-4);
  margin: 0;
}

.hr--g2-ds {
  border-top: 1px solid var(--color-gray2-ds);
  margin: 0;
}

.hr--lilac {
  border-top: 1px solid var(--color-lightlilac);
  margin: 0;
}

.hr-yellow {
  width: 80px;
  height: 10px;
  background-color: #F5BE0E;
  border-radius: 5px;
}

.hr-o {
  border-top: 1px solid var(--color-black);
  margin-top: 36px;
  margin-bottom: 29px;
  position: relative;
}

.hr-o::before {
  content: 'O';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  font-size: var(--font-14);
  font-family: var(--font-regular);
  background: var(--color-white);
  padding: 0 15px;
}

.common-top-radius {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.overflow-y-auto {
  overflow-y: auto;
}

.max-w-none {
  max-width: none !important;
}

.hr--gray {
  border-top: 1px solid var(--color-gray2-ds);
  margin: 0;
}

.icon-reverse {
  transform: rotate(180deg);
}

.font--14 {
  font-size: var(--font-14) !important;
}

.font--16 {
  font-size: var(--font-16) !important;
}
.font--18 {
  font-size: var(--font-18) !important;
}
.font-regular,
.font-regular::placeholder {
  font-family: var(--font-regular) !important;
  font-weight: 400;
}

.break-word {
  word-break: break-all;
}

.width-auto {
  width: auto !important;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-ellipsis--2 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.text-justify {
  text-align: justify !important;
}

.mrn-16-mobile {
  margin-right: -16px;
}

.mrn-16 {
  margin-right: -16px;
}

.img-mw-35 {
  max-height: 35px;
  height: auto;
}

.img-mw-40 {
  max-height: 40px;
  height: auto;
}
.img-mw-65 {
  max-height: 65px;
  height: auto;
}
.txt-nowrap {
  white-space: nowrap;
}

.simple-card {
  border: 1px solid var(--color-gray2-ds);
  border-radius: 0.5rem;
  background-color: #ffffff;
}

.icon-size {
  font-size: var(--font-24);
}

.hr-bottom-gray-2 {
  border-bottom: 1px solid var(--color-gray2-ds);
}

.z-index-99 {
  z-index: 99;
}
.z-index-9999 {
  z-index: 9999;
}
.px-12 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.bg-none {
  background-color: transparent !important;
}

.py-6px {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.semi-bold {
  font-family: var(--font-bold) !important;
  font-weight: 600;
}

.with-border {
  border: 1px solid var(--color-primary);
}

.no-border {
  border: 1px solid transparent !important;
}

.rounded-modal {
  border-radius: 10px;
}

.rounded-light {
  border-radius: 2px;
}
.padding-light {
  padding: 2px;
}
.bg-primary-color {
  background-color: var(--color-primary);
}

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
/* Media Queries */
@media (min-width: 768px) {
  .w-md-50 {
    width: 50%;
  }
  .mrn-16-mobile {
    margin-right: 0;
  }
}

@media (max-width: 992px) {
  .hr-bottom-none {
    border-bottom: 0;
  }

}

@media (min-width: 992px) {
  .hide-on-large {
    opacity: 0;
  }

  .w-lg-50 {
    width: 50% !important;
  }
}

@media (max-width: 380px) {
  .check-item {
    width: 16px;
  }
}
