.content-toasts {
  height: 41px;
  border-radius: 10px;
  position: fixed;
  top: 1rem;
  left: 49.8%;
  transform: translateX(-50%);
  background-color: var(--color-green);
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  transition: all 0.3s linear;
  z-index: 1099;
}

.content-toasts i {
  width: 24px;
  height: 24px;
  font-size: var(--font-24);
}

.content-toasts.show {
  opacity: 1;
}

.content-toasts.hide {
  opacity: 0;
}

@media (max-width: 768px) {
  .content-toasts {
    top: 1rem;
    width: 90%;
    left: 49.4%;
  }
}

@media (min-width: 992px) {
  .content-toasts {
    height: 41px;
    border-radius: 10px;
    position: fixed;
    top: 1rem;
    left: 49.8%;
    transform: translateX(-50%);
    background-color: var(--color-green);
    color: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    transition: all 0.3s linear;
    z-index: 1099;
    width: 60%;
  }
}
