:root {
  --color-primary: #6578ff;
  --color-secondary: #282e55;
  --color-background-primary: #f4f6ff;
  --color-white: white;
  --color-black: black;
  --color-notification: #bf2d73;
  --color-opacity: #6d7278;
  --color-gray-1: #828282;
  --color-gray-2: #c4c4c4;
  --color-gray-3: #9ea2c1;
  --color-gray-4: #a9abbb;
  --color-gray-5: #dde0ec;
  --color-gray-6: #9e9e9e;
  --color-red: #dd4275;
  --color-red2: #d13c84;
  --color-lightlilac: #c9cfff;
  --color-lightlilac-2: #bac3e5;
  --color-ligthlilac-3: #e9e6fe;
  --color-green: #00a19b;
  --color-green2: #46cebe;
  --color-green-3: #4dbab6;
  --color-disabled: #afafaf;
  --color-facebook: #3c5a98;
  --theme-color: var(--color-primary);
  --theme-color-secondary: var(--color-secondary);
  --color-insurance: #0053a1;
  --color-stroke: #dedede;
  --color-primary-ds: #6578ff;
  --theme-color-secondary-ds: #4dbab6;
  --color-dark-blue-ds: #282e55;
  --color-light-ds: #f4f6ff;
  --color-gray1-ds: #a9abbb;
  --color-gray2-ds: #dde0ec;
  --color-error-ds: #db6580;
  --color-highlight-ds: #fed44e;
  --color-primary-sura: #003A70;
  --color-primary-sura: #003A70;
  --color-secondary-sura: #282E55;
  --color-primary-vs: #232172;




  --font-8: 0.5rem;
  --font-10: 0.625rem;
  --font-12: 0.75rem;
  --font-14: 0.875rem;
  --font-16: 1rem;
  --font-18: 1.125rem;
  --font-19: 1.188rem;
  --font-20: 1.25rem;
  --font-22: 1.375rem;
  --font-24: 1.5rem;
  --font-25: 1.563rem;
  --font-27: 1.6875rem;
  --font-30: 1.875rem;
  --font-36: 2.25rem;
  --font-40: 2.5rem;
  --font-48: 3rem;
  --font-52: 3.25rem;
  --font-58: 3.625rem;

  --font-regular: 'Nunito-Regular';
  --font-bold: 'Nunito-Bold';
  --font-light: 'Nunito-Light';
  
  --border-radius: 20px;
  --border-radius-input: 8px;

  --child-1:2;
  --child-2:2;
  --child-3:3;
}