.card-product-simple {
  background-color: var(--color-white);
  border-radius: 10px;
  border: 1px solid var(--color-gray-5);
  padding: 18px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  overflow-x: hidden;
  width: 100%;
}

.card-product-simple {
  width: 100%;
}

.card-product-simple {
  margin-bottom: 12px;
}

@media (min-width: 768px) {
  .card-product-simple {
    max-width: calc(50% - 6px);
    margin-right: 12px;
  }
  .content-card-product .card-product-simple:nth-child(even) {
    margin-right: 0px;
  }
}

@media (min-width: 992px) {
  .card-product-simple {
    max-width: calc(33% - 6px);
    margin-right: 12px;
  }
  .card-product-simple:nth-child(odd) {
    margin-right: 12px;
  }
  .content-card-product .card-product-simple:nth-child(even) {
    margin-right: 12px;
  }
  .card-product-simple:nth-child(3n) {
    margin-right: 0px !important;
  }
}
