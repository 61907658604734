#overlayer {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 1100;
  background: rgba(255, 255, 255, 1);
  left: 0;
  right: 0;
}

/****** LOADER ******/
.sura-loader {
  display: block;
  width: 100px;
  height: 100px;
  top: 45%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 1100;
}

.sura-loader div {
  position: absolute;
  border: 4px solid #00aec7;
  opacity: 1;
  border-radius: 50%;
  animation: sura-loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.sura-loader div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes sura-loader {
  0% {
    top: 50px;
    left: 50px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;
    width: 100px;
    height: 100px;
    opacity: 0;
  }
}
