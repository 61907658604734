.content-card-ms {
  position: relative;
  cursor: pointer;
}

.content-img-ms {
  width: 54px;
  height: 54px;
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  border: 1px solid #bbc4ff;
  border-radius: 30px;
  z-index: 9;
  background: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-img-ms img {
  width: 100%;
  border-radius: 30px;
}

.content-text-ms {
  border: 1px solid #bbc4ff;
  border-radius: 8px;
  background: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px 12px 64px;
  margin-top: 13px;
  overflow:hidden;
}

.content-text-ms i {
  font-size: var(--font-24);
}

.color-alert-ms {
  color: #f5c10a;
}

.color-success-ms {
  color: #4dbab6;
}

.color-warning-ms {
  color: var(--color-error-ds);
}

.color-default-ms {
  color: var(--color-primary-ds);
}
