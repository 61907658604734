/* Card pharmacy */

.content-quotetation {
  display: flex;
  flex-direction: column;
}

/* .content-pharmacies .pharmacy-card {
  width: clamp(320px, 65%, 65%);
  display: flex;
  flex-direction: column;
  margin-right: 0.5rem;
} */
.pharmacy-card {
  border-radius: 16px;
  background-color: var(--color-white);
  border: 1px solid #e9e6fe;
  margin-bottom: 10px;
}

.pharmacy-card .accordion:nth-child(+ 2n) {
  margin-top: -2px;
}

.pharmacy-card .accordion:last-child .card {
  border-bottom: 0 !important;
}

.pharmacy-card .card:nth-child(+ 2n) {
  margin-top: -2px;
}

.pharmacy-card .accordion ~ .card {
  border-bottom: 0 !important;
}

.pharmacy-card--header {
  display: flex;
  justify-content: space-between;
  padding: 19px 0 16px 16px;
}

.pharmacy-card--header-img {
  width: 24px;
  height: 24px;
  border: 1px solid #c8c8c8;
  border-radius: 40px;
}

.pharmacy-card--header-title {
  font-size: var(--font-14);
  font-family: var(--font-bold);
  color: var(--color-secondary);
  margin: 0 7px;
}

.pharmacy-card--header-left {
  display: flex;
  align-items: center;
  width: 65%;
}

.pharmacy-card--header-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: auto;
  justify-content: center;
}

.pharmacy-card--header-right .txt-title {
  font-size: var(--font-12);
  list-style: var(--font-16);
  font-family: var(--font-regular);
  color: var(--color-secondary);
}

.pharmacy-card--header-right .txt-price {
  font-size: var(--font-14);
  list-style: var(--font-16);
  font-family: var(--font-bold);
  color: var(--color-secondary);
}

.pharmacy-card--center .card {
  border-top: 1px solid var(--color-gray-5);
  border-bottom: 0px solid var(--color-gray-5);
  border-left: 0 !important;
  border-right: 0 !important;
  border-radius: 0;
}

.bt-gray-5 {
  border-top: 1px solid var(--color-gray-5);
  padding-top: 1rem !important;
}

.pharmacy-card--center .card-header {
  margin: 0 !important;
  margin-bottom: 1px;
  padding: 16px 8px 0 8px !important;
  
  background-color: var(--color-white) !important;
  border-bottom: 0px !important;
}

.pharmacy-card--center .card-header .col {
  margin: 0 !important;
  padding: 0 !important;
  cursor: pointer;
}

.pharmacy-card--center .card-body {
  padding: 18px 24px !important;
}

.pharmacy-card--bottom {
  margin-top: auto;
  display: flex;
  align-items: center;
  padding: 0 8px;
  padding-bottom: 16px;
}

.content-quotetation--item {
  width: 100%;
}

.content-quotetation--item:first-child hr {
  display: none;
}

.pharmacy-card .dot {
  width: 100px;
  height: 100px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: var(--color-green2);
}

.pharmacy-card .dot-small {
  width: 10px;
  height: 10px;
}

.content-detail {
  border-bottom: 1px solid var(--color-gray-5);
}

.content-detail:nth-last-child(1) {
  border-bottom: 0px !important;
}

.content-detail-img {
  margin-left: 22px;
}

.pharmacy-card--header-right .content-price {
  height: 26px;
  display: flex;
  align-items: center;
  font-size: var(--font-12);
  font-family: var(--font-bold);
  padding: 7px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  /* padding-right: 20px; */
  position: relative;
}

.pharmacy-card--header-right .content-price::before {
  content: '';
  position: absolute;
  background-color: var(--theme-color-secondary);
  width: 100%;
  height: 100%;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  top: 0;
  right: 0;
  opacity: 0.15;
}

.pharmacy-card--header-right .content-price i {
  font-size: var(--font-14);
  color: var(--theme-color-secondary);
  margin-right: 6px;
}

.pharmacy-card--center .content-icon-pharmacy i {
  font-size: var(--font-14);
  color: var(--theme-color-secondary);
}

.alert-price {
  position: relative;
  min-height: 45px !important;
}

.has-benefit {
  text-decoration: line-through;
}

.benefit-badge {
  color: white;
  border-radius: 3px 3px 3px 3px;
  padding-left: 3px;
  padding-right: 3px;
  margin-left: 5px;
  margin-right: 5px;
  min-width: fit-content;
  max-height: 16px;
}

.pharmacy-flag-best-price {
  width: 30px;
  height: 58px;
  font-size: var(--font-58);
  position: absolute;
  left: 12px;
  top: -3px;
}

@media (min-width: 768px) {
  .content-quotetation .pharmacy-card {
    margin-right: 20px;
  }
  .content-pharmacies .pharmacy-card {
    width: 275px;
    display: flex;
    flex-direction: column;
    margin-right: 0.5rem;
  }
  .cards-best-price .pharmacy-card {
    margin-top: 0 !important;
  }
  .cards-best-price .slick-slide:first-child .pharmacy-card {
    margin-top: 0 !important;
  }
  .cards-best-price .pharmacy-card {
    margin-top: 0 !important;
  }
  .cards-best-price .pharmacy-card:first-child {
    margin-top: 0 !important;
  }
  .cards-best-price .slick-slide:first-child .pharmacy-card {
    margin-top: 0 !important;
  }
  .cards-best-price .slick-slide .pharmacy-card {
    margin-top: 45px !important;
  }
  .cards-best-price .pharmacy-card:nth-child(2) {
    margin-top: 45px !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .cards-best-price .pharmacy-card:first-child {
    margin-top: 0 !important;
  }
}

@media (min-width: 768px) and (max-width: 1245px) {
  /* .content-pharmacies .pharmacy-card {
    width: calc(33% - 0.5rem) !important;
  } */
  .grid-xxl  .pharmacy-card:nth-child(3) {
    margin-top: 45px !important;
  }
}


@media (min-width: 992px) {
  .cards-best-price .pharmacy-card:nth-child(2),
  .cards-best-price .pharmacy-card:nth-child(3) {
    margin-top: 45px !important;
  }
  .grid-xxl .pharmacy-card:nth-child(4) {
    margin-top: 45px !important;
  }
  
}

