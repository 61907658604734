/* Card code agreement */
.conetnt-card-code {
  width: 100%;
}

.white-card {
  background-color: var(--color-white);
}

.white-card-info {
  max-width: 330px;
  min-width: 300px;
  max-height: 400px;
  padding: 20px 15px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px 0px #00000040;
  position: relative;
}

.white-card-info img {
  width: 40%;
  max-height: 80px;
}

.blue-border {
  border: 2px solid #6578FF;
}

.new-element {
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
  padding-top: 6px;
  height: 40px;
  background-color: #6477FC;
  border-radius: 7px 0 10px 0;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 600;
}

.card-code {
  border-radius: 8px;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-5);
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.img-agreement {
  width: 35px;
  height: 35px;
}

@media (min-width: 768px) {
  .conetnt-card-code {
    width: calc(50% - 8px);
  }
  .content-btns-share {
    margin-left: 8px;
  }
}

.info-sura-container {
  width: 30%;

}

.info-sura-container-mobile {
  width: 90%;
  padding: 10pxpx;
  margin-bottom: 2rem;
}

.info-card {
  max-width: 407px;
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 2rem;
  margin-left: .5rem;
  margin-right: .5rem;
}