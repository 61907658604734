.card-action-small {
  border-radius: 10px;
  border: 1px solid var(--color-gray-5);
  background: var(--color-white);
  padding: 18px;
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 68px;
}

.card-action-small-btns .btn-next {
  padding: 5px 15px !important;
  white-space: nowrap;
}

@media(min-width: 768px) {
  .card-action-small {
    max-width: calc(50% - 8px);
    margin-right: 8px;
  }
}

@media(min-width: 992px) {
  .card-action-small {
    max-width: calc(33.60% - 8px);
    margin-right: 8px;
  }
  .card-action-small:nth-child(3n) {
    margin-right: 0px;
  }
}