.select-custom {
  position: relative;
  display: inline-flex;
}

.select-custom select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  position: relative;
  font-family: var(--font-regular);
  font-size: var(--font-12);
  line-height: 13px;
  color: var(--color-secondary);
  padding: 8px 38px 8px 21px;
  border-radius: 21px;
  border: 1px solid var(--color-green);
  position: relative;
  background-color: var(--color-white);
}

.select-custom::after {
  content: '';
  position: absolute;
  z-index: 0;
  top: calc(50% - 3px);
  right: 11px;
  width: 8px;
  height: 8px;
  border-left: 1px solid var(--color-green);
  border-bottom: 1px solid var(--color-green);
  transform: rotate(-45deg) translateY(-50%);
}

/* Select Altern */

.select-custom-altern {
  position: relative;
  display: inline-flex;
}

.select-custom-altern .label {
  position: absolute;
  font-size: var(--font-10);
  color: var(--color-gray-4);
  list-style: var(--font-10);
  font-family: var(--font-regular);
  z-index: 10;
  left: 16px;
  top: 5px;
}

.select-custom-altern select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  position: relative;
  font-family: var(--font-regular);
  font-size: var(--font-14);
  line-height: 14px;
  min-height: 40px;
  width: 100%;
  color: var(--color-secondary);
  padding: 18px 30px 6px 14px;
  border-radius: 7px;
  border: 1px solid var(--color-ligthlilac-3);
  position: relative;
  background-color: var(--color-white);
}

.select-custom-altern select option:disabled {
  color: var(--color-gray-1);
}

.select-custom-altern::after {
  content: '';
  position: absolute;
  z-index: 0;
  top: calc(50% - 3px);
  right: 11px;
  width: 8px;
  height: 8px;
  border-left: 1px solid var(--color-secondary);
  border-bottom: 1px solid var(--color-secondary);
  transform: rotate(-45deg) translateY(-50%);
}

/* Input email custom */

.custom-input-email {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid var(--color-secondary);
  height: 30px;
  width: 100%;
  padding: 5px;
  font-family: var(--font-regular);
  color: var(--color-secondary);
  margin-bottom: 12px;
}

.custom-input-email::placeholder {
  color: var(--color-gray-4);
  font-family: var(--font-light);
}

/* New forms inputs */

.input-yapp {
  position: relative;
}

.label {
  font-size: var(--font-12);
  position: absolute;
  top: 16px;
  left: 20px;
  transition: all .24s ease-out;
  margin: 0;
  color: var(--color-gray-4);
  z-index: 10;
}

.label-edit {
  font-size: var(--font-10);
  position: absolute;
  top: 12px;
  right: 16px;
  transition: all .24s ease-out;
  margin: 0;
  color: var(--color-gray-4);
  z-index: 10;
}

.content-input-bg {
  background: var(--color-white);
  border-radius: 8px;
  height: 3rem;
}
.content-textarea-bg {
  background: var(--color-white);
  border-radius: 8px;
  
}
.input-custom-secondary {
  z-index: 11;
  position: relative;
}

.label.active {
  top: 11px;
  font-size: var(--font-10);
}

.view-pass {
  position: absolute;
  top: 15px;
  right: 12px;
  margin: 0;
  width: 24px;
  height: 24px;
}

.view-pass i {
  font-size: var(--font-18);
  color: var(--color-primary);
}

.input-label-center {
  padding: 24px 18px 11px;
}

.input-label-center:focus  ~ .label {
  top: 11px;
  font-size: var(--font-10);
}

.css-w0ogsu-control {
  box-shadow: none!important
}

.css-ov90d8-placeholder {
  color: var(--color-gray-4)!important
}

.css-1vgnvd4-control {
  z-index: 9!important;
}

.input-icon-right {
  display: flex;
  justify-content: flex-end;
  margin-top: -2rem;
  margin-right: 0.5rem;
  margin-bottom: 1.5rem;
}

.input-disabled {
  background: #f4f4ff;
}