.content-select-scroll {
  height: 70%;
}
.content-accordion-list-product {
  padding: 0 1px;
}

.content-accordion-list-product .card {
  border: 0px !important;
  border-bottom: 1px solid #dde0ec !important;
  border-radius: 0;
}

.content-accordion-list-product .card-header {
  padding: 16px;
  border: 0px !important;
  border-radius: 0;
  background-color: var(--color-white);
}

/* .collapse.show .card-body {
  height: 200px;
  overflow-y: auto;
} */