.installButton {
  text-align: center;
  border-radius: var(--border-radius);
  padding-left: 26px;
  padding-right: 26px;
  width: auto;
  height: 40px;
  font-size: var(--font-14);
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-primary);
  border: 1px solid var(--color-primary);
  overflow: hidden;
  color: white !important;
}

.installButton:hover {
  color: white !important;
}
