/* Base  */
.btn-base {
  text-align: center;
  border-radius: var(--border-radius);
  padding-left: 26px;
  padding-right: 26px;
  width: auto;
  height: 40px;
  font-size: var(--font-14);
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: var(--color-primary);
  border: 1px solid var(--color-primary);
  overflow: hidden;
}

/* Size  */
.is-small {
  font-size: var(--font-12);
  line-height: 19px;
  height: 32px;
  border-radius: var(--border-radius);
}

.is-big {
  font-size: var(--font-14);
  line-height: 19px;
  height: 48px;
  border-radius: calc(var(--border-radius) + 3px);
}

.is-chip {
  padding-left: 16px;
  padding-right: 16px;
  height: 26px;
}
/* type color */
.is-stroke {
  color: var(--color-primary);
  background: transparent;
  border: 1px solid var(--color-primary);
}

.is-stroke:disabled {
  color: var(--color-gray-4);
  background: transparent;
  border: 1px solid var(--color-gray-5);
}

/* Icons */
.is-small i {
  font-size: var(--font-16);
}

.btn-base i {
  font-size: var(--font-18);
}

.is-big i {
  font-size: var(--font-18);
}

/* Disabled */

.btn-base:disabled {
  color: var(--color-gray-4) !important;
  background: var(--color-gray-5) !important;
  border: 1px solid var(--color-gray-5) !important;
}

/* hover */
/* .btn-base:hover,
.btn-base:active {
  filter: brightness(0.95);
}
 */
/* Ripple effect */
.ripple {
  position: relative;
}
.ripple::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  transition: background 0.8s;
}
.ripple:hover::before {
  background: rgba(0,0,0,.05) radial-gradient(circle, transparent 1%, rgba(0,0,0,.05) 1%) center/15000%;
}
.ripple:active::before {
  background-color: rgba(0,0,0,.02);
  background-size: 100%;
  transition: background 0s;
}
