.modal-backdrop.show {
  opacity: 0.8 !important;
}

.modal-backdrop {
  background-color: #282e55 !important;
}

.modal-content-preview {
  display: flex;
  justify-content: center;
}
.modal-content-preview-pdf {
  display: flex;
  justify-content: center;
  background-color: transparent !important;
  border: none !important;
}

.modal-header-custom {
  border-bottom: 0 !important;
  border: 0;

  position: relative;
}

.modal-header span {
  color: white;
}
.modal-header-custom .close {
  color: #fff;
  opacity: 1;
  font-size: 36px;
  font-weight: unset;
  text-shadow: none;
  position: absolute;
  padding-bottom: 0.7em;
  outline: none;
  top: -95px;
  right: -195px;
}
.modal-header-custom-pdf .close {
  color: #fff;
  opacity: 1;
  font-size: 36px;
  font-weight: unset;
  text-shadow: none;
  position: absolute;
  padding-bottom: 0.7em;
  outline: none;
  top: -95px;
  right: -195px;
}
modal-header-custom .close:hover {
  color: #fff;
}

.modal-header-custom-uc {
  border-bottom: 0 !important;
  border: 0;
  position: relative;
}
.modal-header-custom-uc .close {
  color: #fff;
  opacity: 1;
  font-size: 48px;
  font-weight: unset;
  text-shadow: none;
  position: absolute;
  padding-bottom: 0.7em;
  outline: none;
  top: -95px;
  right: -195px;
}
modal-header-custom-uc .close:hover {
  color: #fff;
}

@media (max-width: 992px) {
  .modal-header-custom .close {
    right: -20px;
    top: -105px;
  }
  .modal-header-custom-uc .close {
    right: -20px;
    top: -105px;
  }
}
@media (max-width: 767px) {
  .modal-header-custom .close {
    right: 0px;
    top: -105px;
  }
  .modal-header-custom-pdf .close {
    right: 0px;
    top: -350px;
  }
  .modal-header-custom-uc .close {
    right: 0px;
    top: -105px;
  }
}
