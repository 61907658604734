.title-home {
  font-family: var(--font-bold);
  font-size: var(--font-16);
  line-height: 19px;
  color: var(--color-secondary);
}

.title-home-sura {
  font-family: var(--font-bold);
  font-size: var(--font-27);
  line-height: 28px;
  color: var(--color-primary-sura);
}

.title-home-lg-sura {
  font-family: var(--font-bold);
  font-size: var(--font-25);
  line-height: 35px;
  color: var(--color-primary-sura);
}

.title-home-vs {
  font-family: var(--font-bold);
  font-size: var(--font-27);
  line-height: 28px;
  color: var(--color-primary-vs);
}

.subtitle-home-sura {
  font-family: var(--font-regular);
  font-size: var(--font-22);
  line-height: 32px;
  color: var(--color-secondary-sura);
}

.text-home-light {
  font-family: var(--font-light);
  font-size: var(--font-22);
  line-height: 32px;
  color: var(--color-secondary-sura);
}

.title-primary--3xl,
.title-primary--3xl-bold {
  font-family: var(--font-bold);
  font-size: var(--font-22);
  line-height: 22px;
  color: var(--color-secondary);
}

.title-primary--3xl-regular {
  font-family: var(--font-regular);
  font-size: var(--font-22);
  line-height: 22px;
  color: var(--color-secondary);
}

.title-primary--xxl {
  font-family: var(--font-bold);
  font-size: var(--font-18);
  line-height: 19px;
  color: var(--color-secondary);
}
.title-widget {
  font-family: var(--font-bold);
  font-size: var(--font-18);
  line-height: 19px;
  color: var(--color-secondary);
}
.title-widget--xl {
  font-family: var(--font-bold);
  font-size: var(--font-24);
  line-height: 26px;
  color: var(--color-secondary);
}

.title-primary--xxl-regular {
  font-family: var(--font-regular);
  font-size: var(--font-18);
  line-height: 25px;
  color: var(--color-secondary);
}

.title-primary--xl {
  font-family: var(--font-regular);
  font-size: var(--font-16);
  line-height: 19px;
  color: var(--color-secondary);
}

.title-primary-sura--xl {
  font-family: var(--font-regular);
  font-size: var(--font-16);
  line-height: 19px;
  color: var(--color-primary-sura);
}

.title-primary--xl-bold {
  font-family: var(--font-bold);
  font-size: var(--font-16);
  line-height: 19px;
  color: var(--color-secondary);
}

.title-primary {
  font-family: var(--font-bold);
  font-size: var(--font-14);
  line-height: 19px;
  color: var(--color-secondary);
}

.title-primary--regular {
  font-family: var(--font-regular);
  font-size: var(--font-14);
  line-height: 19px;
  color: var(--color-secondary);
}

.title-primary--light {
  font-family: var(--font-light);
  font-size: var(--font-14);
  line-height: 19px;
  color: var(--color-secondary);
}

.title-big {
  font-family: var(--font-bold) !important;
  font-size: var(--font-20);
  line-height: 27px;
}

.title-quotation {
  font-family: var(--font-bold) !important;
  font-size: var(--font-20);
  line-height: 27px;
}

.title-modal {
  font-family: var(--font-regular);
  font-size: var(--font-16);
  line-height: 22px;
  text-align: center;
  color: var(--color-primary);
  margin-bottom: 40px;
}

.title-modal-altern {
  font-family: var(--font-regular);
  font-size: var(--font-24);
  line-height: var(--font-24);
  text-align: center;
  color: var(--color-primary);
}

.txt-title {
  font-family: var(--font-bold);
  font-size: var(--font-24);
}

.txt-xl-subtitle {
  font-family: var(--font-regular);
  font-size: var(--font-24);
  color: var(--color-dark-blue-ds);
}

.txt-bold {
  font-family: var(--font-bold) !important;
}

.txt-red {
  font-family: var(--font-light);
  font-size: var(--font-16);
  color: var(--color-red);
}

.txt-black {
  font-family: var(--font-bold);
  font-size: var(--font-20);
}

.txt-opacity {
  font-family: var(--font-light);
  font-size: var(--font-16);
  color: var(--color-opacity);
}

.txt-primary {
  font-family: var(--font-bold);
  font-size: var(--font-16);
  color: var(--color-primary)
}

.txt-secondary {
  font-family: var(--font-light);
  font-size: var(--font-14);
  color: var(--color-secondary)
}

.txt-paragraph--bold {
  font-family: var(--font-bold);
  font-weight: normal;
  font-size: var(--font-12) ;
  line-height: var(--font-12);
  color: var(--color-dark-blue-ds);
}

.txt-paragraph--lg-bold {
  font-family: var(--font-bold);
  font-weight: normal;
  font-size: var(--font-14) ;
  line-height: var(--font-12);
  color: var(--color-dark-blue-ds);
}

.txt-error {
  color: var(--color-red);
}

.txt-paragraph {
  font-family: var(--font-regular);
  font-weight: normal;
  font-size: var(--font-12);
  line-height: var(--font-12);
  color: var(--color-dark-blue-ds);
}

.txt-paragraph-lh {
  font-family: var(--font-regular);
  font-weight: normal;
  font-size: var(--font-12);
  line-height: 1rem;
}

.txt-paragraph--bold {
  font-family: var(--font-bold);
  font-weight: normal;
  font-size: var(--font-12);
  line-height: var(--font-12);
}

.txt-paragraph--light {
  font-family: var(--font-light);
  font-weight: normal;
  font-size: var(--font-12);
  line-height: var(--font-12);
}

.txt-paragraph-gray--light {
  font-family: var(--font-light);
  font-weight: normal;
  font-size: var(--font-12);
  line-height: var(--font-16);
  color: #ADB3CB;
}

.txt-paragraph--small {
  font-family: var(--font-light);
  font-weight: normal;
  font-size: var(--font-10);
  line-height: var(--font-10);
}

.txt-paragraph-gray--small {
  font-family: var(--font-light);
  font-weight: normal;
  font-size: var(--font-10);
  line-height: var(--font-14);
  color: #ADB3CB;
}

.txt-paragraph--medium {
  font-family: 'Nunito-Light';
  font-weight: normal;
  font-size: var(--font-12);
  line-height: var(--font-10);
}

.txt-line--bold {
  font-family: var(--font-bold);
  font-weight: normal;
  font-size: var(--font-10);
  line-height: var(--font-10);
}

.txt-line--bold-lh {
  font-family: var(--font-bold);
  font-weight: normal;
  font-size: var(--font-10);
  line-height: 0.8125rem;
}

.txt-line {
  font-family: var(--font-regular);
  font-weight: normal;
  font-size: var(--font-10);
  line-height: var(--font-10);
}

.txt-line-lh {
  font-family: var(--font-regular);
  font-weight: normal;
  font-size: var(--font-10);
  line-height: 0.8125rem;
}

.txt-sufix {
  font-family: var(--font-bold);
  font-size: var(--font-8);
  color: var(--color-gray-1)
}

.txt-sufix-through {
  text-decoration: line-through;
  font-size: var(--font-8);
}

.text-color--primary {
  color: var(--color-primary)
}

.text-color--secondary {
  color: var(--color-secondary)
}

.txt-white {
  font-family: var(--font-light);
  font-size: var(--font-14);
  color: var(--color-white)
}

.txt-line-through {
  text-decoration: line-through;
  font-size: var(--font-14);
}

.title-error {
  font-family: var(--font-regular);
  font-size: var(--font-18);
  line-height: 25px;
  margin: 34px 0 22px;
}

.link {
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s linear;
}

.link:hover {
  text-decoration: none;
  color: var(--color-secondary);
}

.txt-light {
  font-family: var(--font-light);
}

.txt-form-error {
  font-size: var(--font-10);
  font-family: var(--font-regular);
  color: var(--color-red);
  margin-left: 20px;
}

/* Typography from design system */
/* Base
txt-1--bold     -> 22 bold
txt-2--bold     -> 18 bold
txt-2--regular  -> 18 regular
txt-3--bold     -> 16 bold
txt-3--light    -> 16 light
txt-4--bold     -> 14 bold
txt-4--regular  -> 14 regular
txt-5--bold     -> 12 bold
txt-5--regular  -> 12 regular
txt-6--bold     -> 10 bold
txt-6--regular  -> 10 regular

Example typography style 1 with color
txt-1--bold-pr -> primary
txt-1--bold-se -> secondary
txt-1--bold-db -> dark blue
txt-1--bold-li -> light
txt-1--bold-g1 -> gray1
txt-1--bold-g2 -> gray2
txt-1--bold-er -> error
*/


/* Responsive */
.txt-3--bold-resp {
  font-family: var(--font-bold);
  font-size: var(--font-16);
  line-height: var(--font-22);
}

@media (max-width: 767px) {
  .txt-4--regular-db {
    font-size: var(--font-12) !important;
    line-height: var(--font-16);
  }
}

@media (min-width: 768px) {
  .txt-4--regular-db {
    font-size: var(--font-14);
    line-height: var(--font-16);
  }
  .txt-40--bold {
    font-size: 36px;
  }
}
@media (max-width: 767px) {
  .txt-3--bold-resp {
    font-size: var(--font-12);
    line-height: var(--font-16);
  }
}

@media (max-width: 320px) {
  .txt-40--bold {
    font-size: var(--font-30) !important;
  }
}


/* Titulo Bold XXL */
.txt-40--bold {
  font-family: var(--font-bold);
  font-size: var(--font-40);
  line-height: var(--font-36);
}

.txt-30--bold {
  font-family: var(--font-bold);
  font-size: var(--font-30);
  line-height: var(--font-30);
}

.txt-36--bold {
  font-family: var(--font-bold);
  font-size: var(--font-36);
  line-height: var(--font-30);
}


/* Título Bold XL (22) */
.txt-1--bold {
  font-family: var(--font-bold);
  font-size: var(--font-22);
  line-height: var(--font-30);
}

.txt-1--bold-pr {
  font-family: var(--font-bold);
  font-size: var(--font-22);
  line-height: var(--font-30);
  color: var(--color-primary-ds);
}

.txt-1--bold-se {
  font-family: var(--font-bold);
  font-size: var(--font-22);
  line-height: var(--font-30);
  color: var(--theme-color-secondary);
}

.txt-1--bold-db {
  font-family: var(--font-bold);
  font-size: var(--font-22);
  line-height: var(--font-30);
  color: var(--color-dark-blue-ds);
}

.txt-1--bold-li {
  font-family: var(--font-bold);
  font-size: var(--font-22);
  line-height: var(--font-30);
  color: var(--color-light-ds);
}

.txt-1--bold-g1 {
  font-family: var(--font-bold);
  font-size: var(--font-22);
  line-height: var(--font-30);
  color: var(--color-gray1-ds);
}

.txt-1--bold-g2 {
  font-family: var(--font-bold);
  font-size: var(--font-22);
  line-height: var(--font-30);
  color: var(--color-gray2-ds);
}

.txt-1--bold-er {
  font-family: var(--font-bold);
  font-size: var(--font-22);
  line-height: var(--font-30);
  color: var(--color-error-ds);
}

/* Título Regular XL (22) */
.txt-1--regular {
  font-family: var(--font-regular);
  font-size: var(--font-22);
  line-height: var(--font-30);
}

.txt-1--regular-pr {
  font-family: var(--font-regular);
  font-size: var(--font-22);
  line-height: var(--font-30);
  color: var(--color-primary-ds);
}

.txt-1--regular-se {
  font-family: var(--font-regular);
  font-size: var(--font-22);
  line-height: var(--font-30);
  color: var(--theme-color-secondary);
}

.txt-1--regular-db {
  font-family: var(--font-regular);
  font-size: var(--font-22);
  line-height: var(--font-30);
  color: var(--color-dark-blue-ds);
}

.txt-1--regular-li {
  font-family: var(--font-regular);
  font-size: var(--font-22);
  line-height: var(--font-30);
  color: var(--color-light-ds);
}

.txt-1--regular-g1 {
  font-family: var(--font-regular);
  font-size: var(--font-22);
  line-height: var(--font-30);
  color: var(--color-gray1-ds);
}

.txt-1--regular-g2 {
  font-family: var(--font-regular);
  font-size: var(--font-22);
  line-height: var(--font-30);
  color: var(--color-gray2-ds);
}

.txt-1--regular-er {
  font-family: var(--font-regular);
  font-size: var(--font-22);
  line-height: var(--font-30);
  color: var(--color-error-ds);
}

/* Titulo bold 30 */
.txt-xl--bold {
  font-family: var(--font-bold);
  font-size: var(--font-30);
  line-height: var(--font-30);
}

/* Subtítulo Bold (18) */
.txt-2--bold {
  font-family: var(--font-bold);
  font-size: var(--font-18);
  line-height: var(--font-24);
}

.txt-2--bold-pr {
  font-family: var(--font-bold);
  font-size: var(--font-18);
  line-height: var(--font-24);
  color: var(--color-primary-ds);
}

.txt-2--bold-se {
  font-family: var(--font-bold);
  font-size: var(--font-18);
  line-height: var(--font-24);
  color: var(--theme-color-secondary);
}

.txt-2--bold-db {
  font-family: var(--font-bold);
  font-size: var(--font-18);
  line-height: var(--font-24);
  color: var(--color-dark-blue-ds);
}

.txt-2--bold-li {
  font-family: var(--font-bold);
  font-size: var(--font-18);
  line-height: var(--font-24);
  color: var(--color-light-ds);
}

.txt-2--bold-g1 {
  font-family: var(--font-bold);
  font-size: var(--font-18);
  line-height: var(--font-24);
  color: var(--color-gray1-ds);
}

.txt-2--bold-g2 {
  font-family: var(--font-bold);
  font-size: var(--font-18);
  line-height: var(--font-24);
  color: var(--color-gray2-ds);
}

.txt-2--bold-er {
  font-family: var(--font-bold);
  font-size: var(--font-18);
  line-height: var(--font-24);
  color: var(--color-error-ds);
}

/* Subtítulo Regular (18) */
.txt-2--regular {
  font-family: var(--font-regular);
  font-size: var(--font-18);
  line-height: var(--font-24);
}

.txt-2--regular-pr {
  font-family: var(--font-regular);
  font-size: var(--font-18);
  line-height: var(--font-24);
  color: var(--color-primary-ds);
}

.txt-2--regular-se {
  font-family: var(--font-regular);
  font-size: var(--font-18);
  line-height: var(--font-24);
  color: var(--theme-color-secondary);
}

.txt-2--regular-db {
  font-family: var(--font-regular);
  font-size: var(--font-18);
  line-height: var(--font-24);
  color: var(--color-dark-blue-ds);
}

.txt-2--regular-li {
  font-family: var(--font-regular);
  font-size: var(--font-18);
  line-height: var(--font-24);
  color: var(--color-light-ds);
}

.txt-2--regular-g1 {
  font-family: var(--font-regular);
  font-size: var(--font-18);
  line-height: var(--font-24);
  color: var(--color-gray1-ds);
}

.txt-2--regular-g2 {
  font-family: var(--font-regular);
  font-size: var(--font-18);
  line-height: var(--font-24);
  color: var(--color-gray2-ds);
}

.txt-2--regular-er {
  font-family: var(--font-regular);
  font-size: var(--font-18);
  line-height: var(--font-24);
  color: var(--color-error-ds);
}

.txt-3--regular-er {
  font-family: var(--font-regular);
  font-size: var(--font-16) !important;
  line-height: var(--font-22);
  color: var(--color-error-ds) !important;
}

/* Bajada Bold XL (16) */
.txt-3--bold {
  font-family: var(--font-bold);
  font-size: var(--font-16) !important;
  line-height: var(--font-22);
}

.txt-3--bold-pr {
  font-family: var(--font-bold);
  font-size: var(--font-16);
  line-height: var(--font-22);
  color: var(--color-primary-ds);
}

.txt-3--regular-pr {
  font-family: var(--font-regular) !important;
  font-size: var(--font-16) !important;
  line-height: var(--font-22) ;
  color: var(--color-primary-ds) !important;
}

.txt-3--bold-se {
  font-family: var(--font-bold);
  font-size: var(--font-16);
  line-height: var(--font-22);
  color: var(--theme-color-secondary);
}

.txt-3--bold-db {
  font-family: var(--font-bold);
  font-size: var(--font-16);
  line-height: var(--font-22);
  color: var(--color-dark-blue-ds);
}

.txt-3--bold-li {
  font-family: var(--font-bold);
  font-size: var(--font-16);
  line-height: var(--font-22);
  color: var(--color-light-ds);
}

.txt-3--bold-g1 {
  font-family: var(--font-bold);
  font-size: var(--font-16);
  line-height: var(--font-22);
  color: var(--color-gray1-ds);
}

.txt-3--bold-g2 {
  font-family: var(--font-bold);
  font-size: var(--font-16);
  line-height: var(--font-22);
  color: var(--color-gray2-ds);
}

.txt-3--bold-er {
  font-family: var(--font-bold);
  font-size: var(--font-16);
  line-height: var(--font-22);
  color: var(--color-error-ds);
}

/* Bajada Regular XL (16) */
.txt-3--regular-db {
  font-family: var(--font-regular);
  font-size: var(--font-16);
  line-height: var(--font-19);
  color: var(--color-dark-blue-ds);
}
/* Bajada Light XL (16) */
.txt-3--light {
  font-family: var(--font-light);
  font-size: var(--font-16);
  line-height: var(--font-22);
}

.txt-3--light-pr {
  font-family: var(--font-light);
  font-size: var(--font-16);
  line-height: var(--font-22);
  color: var(--color-primary-ds);
}

.txt-3--light-se {
  font-family: var(--font-light);
  font-size: var(--font-16);
  line-height: var(--font-22);
  color: var(--theme-color-secondary);
}

.txt-3--light-db {
  font-family: var(--font-light);
  font-size: var(--font-16);
  line-height: var(--font-22);
  color: var(--color-dark-blue-ds);
}

.txt-3--light-li {
  font-family: var(--font-light);
  font-size: var(--font-16);
  line-height: var(--font-22);
  color: var(--color-light-ds);
}

.txt-3--light-g1 {
  font-family: var(--font-light);
  font-size: var(--font-16);
  line-height: var(--font-22);
  color: var(--color-gray1-ds);
}

.txt-3--light-g2 {
  font-family: var(--font-light);
  font-size: var(--font-16);
  line-height: var(--font-22);
  color: var(--color-gray2-ds);
}

.txt-3--light-er {
  font-family: var(--font-light);
  font-size: var(--font-16);
  line-height: var(--font-22);
  color: var(--color-error-ds);
}

/* Cuerpo M Bold (14) */
.txt-4--bold {
  font-family: var(--font-bold);
  font-size: var(--font-14);
  line-height: var(--font-19);
}

.txt-4--bold-pr {
  font-family: var(--font-bold);
  font-size: var(--font-14);
  line-height: var(--font-19);
  color: var(--color-primary-ds);
}

.txt-4--bold-se {
  font-family: var(--font-bold);
  font-size: var(--font-14);
  line-height: var(--font-19);
  color: var(--theme-color-secondary);
}

.txt-4--bold-db {
  font-family: var(--font-bold);
  font-size: var(--font-14);
  line-height: var(--font-19);
  color: var(--color-dark-blue-ds);
}

.txt-4--bold-dc {
  font-family: var(--font-bold);
  font-size: var(--font-14);
  line-height: var(--font-19);
  color: var(--color-green-3);
}

.txt-4--bold-li {
  font-family: var(--font-bold);
  font-size: var(--font-14);
  line-height: var(--font-19);
  color: var(--color-light-ds);
}

.txt-4--bold-g1 {
  font-family: var(--font-bold);
  font-size: var(--font-14);
  line-height: var(--font-19);
  color: var(--color-gray1-ds);
}

.txt-4--bold-g2 {
  font-family: var(--font-bold);
  font-size: var(--font-14);
  line-height: var(--font-19);
  color: var(--color-gray2-ds);
}

.txt-4--bold-er {
  font-family: var(--font-bold);
  font-size: var(--font-14);
  line-height: var(--font-19);
  color: var(--color-error-ds);
}

/* Cuerpo M Regular (14) */
.txt-4--regular {
  font-family: var(--font-regular);
  font-size: var(--font-14);
  line-height: var(--font-19);
}

.txt-4--regular-pr {
  font-family: var(--font-regular);
  font-size: var(--font-14);
  line-height: var(--font-19);
  color: var(--color-primary-ds);
}

.txt-4--regular-se {
  font-family: var(--font-regular);
  font-size: var(--font-14);
  line-height: var(--font-19);
  color: var(--theme-color-secondary);
}

.txt-4--regular-db {
  font-family: var(--font-regular);
  font-size: var(--font-14);
  line-height: var(--font-19);
  color: var(--color-dark-blue-ds);
}

.txt-4--regular-li {
  font-family: var(--font-regular);
  font-size: var(--font-14);
  line-height: var(--font-19);
  color: var(--color-light-ds);
}

.txt-4--regular-g1 {
  font-family: var(--font-regular);
  font-size: var(--font-14);
  line-height: var(--font-19);
  color: var(--color-gray1-ds);
}

.txt-4--regular-g2 {
  font-family: var(--font-regular);
  font-size: var(--font-14);
  line-height: var(--font-19);
  color: var(--color-gray2-ds);
}

.txt-4--regular-er {
  font-family: var(--font-regular);
  font-size: var(--font-14);
  line-height: var(--font-19);
  color: var(--color-error-ds);
}

/* Cuerpo S Bold (12) */
.txt-5--bold {
  font-family: var(--font-bold);
  font-size: var(--font-12);
  line-height: var(--font-16);
}

.txt-5--bold-pr {
  font-family: var(--font-bold);
  font-size: var(--font-12);
  line-height: var(--font-16);
  color: var(--color-primary-ds);
}

.txt-5--bold-se {
  font-family: var(--font-bold);
  font-size: var(--font-12);
  line-height: var(--font-16);
  color: var(--theme-color-secondary);
}

.txt-5--bold-db {
  font-family: var(--font-bold);
  font-size: var(--font-12);
  line-height: var(--font-16);
  color: var(--color-dark-blue-ds);
}

.txt-5--bold-dc {
  font-family: var(--font-bold);
  font-size: var(--font-12);
  line-height: var(--font-16);
  color: var(--color-green-3);
}

.txt-5--bold-li {
  font-family: var(--font-bold);
  font-size: var(--font-12);
  line-height: var(--font-16);
  color: var(--color-light-ds);
}

.txt-5--bold-g1 {
  font-family: var(--font-bold);
  font-size: var(--font-12);
  line-height: var(--font-16);
  color: var(--color-gray1-ds);
}

.txt-5--bold-g2 {
  font-family: var(--font-bold);
  font-size: var(--font-12);
  line-height: var(--font-16);
  color: var(--color-gray2-ds);
}

.txt-5--bold-er {
  font-family: var(--font-bold);
  font-size: var(--font-12);
  line-height: var(--font-16);
  color: var(--color-error-ds);
}

/* Cuerpo S Regular (12) */
.txt-5--regular {
  font-family: var(--font-regular);
  font-size: var(--font-12);
  line-height: var(--font-16);
}

.txt-5--regular-pr {
  font-family: var(--font-regular);
  font-size: var(--font-12);
  line-height: var(--font-16);
  color: var(--color-primary-ds);
}

.txt-5--regular-se {
  font-family: var(--font-regular);
  font-size: var(--font-12);
  line-height: var(--font-16);
  color: var(--theme-color-secondary);
}

.txt-5--regular-db {
  font-family: var(--font-regular);
  font-size: var(--font-12);
  line-height: var(--font-16);
  color: var(--color-dark-blue-ds);
}

.txt-5--regular-li {
  font-family: var(--font-regular);
  font-size: var(--font-12);
  line-height: var(--font-16);
  color: var(--color-light-ds);
}

.txt-5--regular-g1 {
  font-family: var(--font-regular);
  font-size: var(--font-12);
  line-height: var(--font-16);
  color: var(--color-gray1-ds);
}

.txt-5--regular-g2 {
  font-family: var(--font-regular);
  font-size: var(--font-12);
  line-height: var(--font-16);
  color: var(--color-gray2-ds);
}

.txt-5--regular-er {
  font-family: var(--font-regular);
  font-size: var(--font-12);
  line-height: var(--font-16);
  color: var(--color-error-ds);
}

/* Mínimo Bold (10) */
.txt-6--bold {
  font-family: var(--font-bold);
  font-size: var(--font-10);
  line-height: var(--font-14);
}

.txt-6--bold-pr {
  font-family: var(--font-bold);
  font-size: var(--font-10);
  line-height: var(--font-14);
  color: var(--color-primary-ds);
}

.txt-6--bold-se {
  font-family: var(--font-bold);
  font-size: var(--font-10);
  line-height: var(--font-14);
  color: var(--theme-color-secondary);
}

.txt-6--bold-db {
  font-family: var(--font-bold);
  font-size: var(--font-10);
  line-height: var(--font-14);
  color: var(--color-dark-blue-ds);
}

.txt-6--bold-li {
  font-family: var(--font-bold);
  font-size: var(--font-10);
  line-height: var(--font-14);
  color: var(--color-light-ds);
}

.txt-6--bold-g1 {
  font-family: var(--font-bold);
  font-size: var(--font-10);
  line-height: var(--font-14);
  color: var(--color-gray1-ds);
}

.txt-6--bold-g2 {
  font-family: var(--font-bold);
  font-size: var(--font-10);
  line-height: var(--font-14);
  color: var(--color-gray2-ds);
}

.txt-6--bold-er {
  font-family: var(--font-bold);
  font-size: var(--font-10);
  line-height: var(--font-14);
  color: var(--color-error-ds);
}

/* Mínimo Regular (10) */
.txt-6--regular {
  font-family: var(--font-regular);
  font-size: var(--font-10);
  line-height: var(--font-14);
}

.txt-6--regular-pr {
  font-family: var(--font-regular);
  font-size: var(--font-10);
  line-height: var(--font-14);
  color: var(--color-primary-ds);
}

.txt-6--regular-se {
  font-family: var(--font-regular);
  font-size: var(--font-10);
  line-height: var(--font-14);
  color: var(--theme-color-secondary);
}

.txt-6--regular-db {
  font-family: var(--font-regular);
  font-size: var(--font-10);
  line-height: var(--font-14);
  color: var(--color-dark-blue-ds);
}

.txt-6--regular-li {
  font-family: var(--font-regular);
  font-size: var(--font-10);
  line-height: var(--font-14);
  color: var(--color-light-ds);
}

.txt-6--regular-g1 {
  font-family: var(--font-regular);
  font-size: var(--font-10);
  line-height: var(--font-14);
  color: var(--color-gray1-ds);
}

.txt-6--regular-g2 {
  font-family: var(--font-regular);
  font-size: var(--font-10);
  line-height: var(--font-14);
  color: var(--color-gray2-ds);
}

.txt-6--regular-er {
  font-family: var(--font-regular);
  font-size: var(--font-12);
  line-height: var(--font-14);
  color: var(--color-error-ds);
}

@media (max-width: 380px) {
  .title-home-sura {
    font-family: var(--font-regular);
    font-size: var(--font-18);
    line-height: 22px;
    color: var(--color-primary-sura);
  }

  .subtitle-home-sura {
    font-family: var(--font-light);
    font-size: var(--font-16);
    line-height: 20px;
    color: var(--color-secondary-sura);
  }

  .title-home-lg-sura {
    font-family: var(--font-bold);
    font-size: var(--font-18);
    line-height: 25px;
    color: var(--color-primary-sura);
    text-align: center;
  }

  .text-home-light {
    font-family: var(--font-light);
    font-size: var(--font-16);
    line-height: 22px;
    color: var(--color-secondary-sura);
  }

  .title-home-vs {
    font-family: var(--font-regular);
    font-size: var(--font-18);
    line-height: 22px;
    color: var(--color-primary-vs);
  }

  .txt-xl-subtitle {
    font-family: var(--font-light);
    font-size: var(--font-16);
    color: var(--color-dark-blue-ds);
  }
}