.content-card-comming {
  border-radius: 8px;
  background-color: var(--color-white);
  padding: 40px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.img-comming {
  height: 114px;
  margin: 0 auto 24px;
}

@media (min-width: 769px) {
  .content-card-comming {
    max-width: 288px;
  }
}
