.wrapper {
  background-color: #F3F6FF;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.frame {
  width: 100%;
  overflow: hidden; 
}

.box-container {
  display: flex;
}

.box {
  box-sizing: border-box;
  width: 100%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.box img {
  width: 70%;
  height: 280px;
  margin-bottom: 60px;
}

.box-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 10px 20px 20px;
  min-height: 230px;
  border-radius: 40px 40px 0 0;
  margin: 0 10px 0 5px;
}

.box-text h2 {
  font-family: var(--font-bold);
  font-size: var(--font-24);
  margin-bottom: 10px;
}

.box-text h3 {
  font-family: var(--font-regular);
  font-size: var(--font-18);
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding-bottom: 40px;
}

.skipButton {
  color: #6578FF;
  text-align: center;
  padding: 5px 40px;
  margin: 20px;
  user-select: none;
  cursor: pointer;
}

.skipButton:hover {
  text-decoration: underline;
}

.nextButton {
  background: #6578FF;
  color: #fff;
  text-align: center;
  padding: 5px 40px;
  border-radius: 30px;
  margin: 20px;
  user-select: none;
  cursor: pointer;
}

.button-2-container {
  display: flex;
  position: absolute;
  top: 50%;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.button-2 {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #D9DEFF;
  margin: 6px;
  cursor: pointer;
}

.active-tab {
  background: #6578FF;
}