.carousel-pharmacy {
  display: flex;
  height: 100px;
  justify-content: center;
  width: 100%;
  flex-wrap: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  scrollbar-width: none;
  transition: 1s ease-in;
  background-color: #ffffff;
}

.ellipse-carousel {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-regular);
  width: .8rem;
  height: .8rem;
  border-radius: 50%;
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-12);
  line-height: 16px;
  color: #ffffff;
  background-color: #6578FF;
}

.carousel-img {
  width: 90%;
  aspect-ratio: 3/2;
  object-fit: contain;
}
