.content-card-mso {
  background: var(--color-white);
  border-radius: var(--border-radius-input);
  border: 1px solid var(--color-gray2-ds);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  cursor: pointer;
}

.content-txt-mso {
  padding-right: 0.5rem;
}

.icon-mso {
  font-size: var(--font-24);
}
