.color-lilac {
  color: var(--color-primary) !important;
}

.color-green {
  color: var(--color-green) !important;
}

.color-darkblue-ds

.color-alert {
  color: var(--color-red2) !important;
}

.color-disabled {
  color: var(--color-disabled) !important;
}

.color-gray {
  color: var(--color-gray-1) !important;
}

.color-gray-2 {
  color: var(--color-gray-2) !important;
}

.color-gray-3 {
  color: var(--color-gray-3) !important;
}

.color-gray-4 {
  color: var(--color-gray-4) !important;
}

.color-gray-5 {
  color: var(--color-gray-5);
}

.color-gray-6 {
  color: var(--color-gray-6);
}

.color-secondary {
  color: var(--color-secondary) !important;
}

.color-primary {
  color: var(--color-primary) !important;
}

.color-theme {
  color: var(--theme-color) !important ;
}

.color-theme-secondary {
  color: var(--theme-color-secondary);
}

.color-white {
  color: var(--white) !important;
}

.color-secondary-ds {
  color: var(--theme-color-secondary) !important;
}

.color-darkblue-ds {
  color: var(--color-dark-blue-ds) !important;
}

.color-gray1-ds {
  color: var(--color-gray1-ds) !important;
}

.background-theme {
  background-color: var(--theme-color) !important;
}

.background-green {
  background-color: var(--color-green) !important;
}

.background-white {
  background-color: var(--white) !important;
}

.no-color {
  color: transparent !important;
}

.color-insurance {
  color: var(--color-insurance) !important;
}

.background-insurance {
  background-color: var(--color-insurance) !important;
}
