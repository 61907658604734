.player {
  max-width: 288px;
  position: relative;
  font-size: 0;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid var(--color-gray-5);
  margin-bottom: 16px;
}

/* This css is only applied when fullscreen is active. */

.player:fullscreen {
  max-width: none;
  width: 100%;
}

.player:-webkit-full-screen {
  max-width: none;
  width: 100%;
}

.player__video {
  width: 100%;
}