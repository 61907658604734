.slick-list, .slick-slider, .slick-track {
  position: relative;
  display: block
}

.slick-loading .slick-slide, .slick-loading .slick-track {
  visibility: hidden
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent
}

.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0
}

.slick-list:focus {
  outline: 0
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand
}

.slick-slider .slick-list, .slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.slick-track {
  top: 0;
  left: 0
}

.slick-track:after, .slick-track:before {
  display: table;
  content: ''
}

.slick-track:after {
  clear: both
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px
}

[dir=rtl] .slick-slide {
  float: right
}

.slick-slide img {
  display: block
}

.slick-slide.slick-loading img {
  display: none
}

.slick-slide.dragging img {
  pointer-events: none
}

.slick-initialized .slick-slide {
  display: block
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent
}

.slick-arrow.slick-hidden {
  display: none
}

/*# sourceMappingURL=slick.min.css.map */

.slick-dots {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex !important;
  justify-content: center;
}

.slick-dots li button {
  border: 0;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  font-size: var(--font-8);
  margin: 0 1px;
  background: rgba(0, 0, 0, 0.12);
}

.slick-slider {
  max-width: 980px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.slick-arrow {
  border: 0;
  background: transparent;
  width: 19px;
  height: 30px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 50%;
  display: flex !important;
  align-items: center;
  transition: all 0.4s linear;
}

.slick-arrow i {
  color: var(--theme-color);
  font-size: var(--font-24);
  cursor: pointer;
}

.slick-prev {
  transform: translateY(-50%) rotate(180deg);
  z-index: 1050;
  left: -30px;
}

.slick-next {
  transform: translateY(-50%);
  z-index: 1050;
  right: -30px;
}

.slick-disabled {
  display: none !important;
}

.slick-slide {
  max-width: 306px !important;
  margin-right: 8px !important;
}