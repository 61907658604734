* {
  scroll-behavior: smooth;
}

button {
  border: 0;
}

.main-footer {
  border-top: 1px solid var(--color-gray2-ds);
  min-height: 4rem;
}

button.main-footer {
  border: 0;
  box-sizing: border-box;
  border-top: 1px solid var(--color-gray2-ds);
}

.scroll-container {
  min-height: calc(100vh - 6.0625rem);
  height: calc(100vh - 6.0625rem);
  overflow-y: auto;
}
.card-programs-img {
  max-width: 62px;
  max-height: 62px;
}

.card-agreements-img {
  max-width: 48px;
  max-height: 48px;
  border-radius: 9999px;
}
.brand-agreements-img {
  max-width: 38px;
  max-height: 38px;
  border-radius: 9999px;
}
.agreement-card {
  background: #fff;
  padding: 25px;
  border-radius: 8px;
  border: 1px solid #dde0ec
}
.agreement-card-content {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.agreement-card-content img {
  margin-right: 24px;
  height: 100px;
}
.agreement-card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content-container {
  min-height: calc(100vh - 9.8125rem);
}

.btn-share-sidenav {
  display: flex;
  align-items: center;
  background-color: var();
  background-color: #f4f6ff;
  border-radius: 1.875rem;
  min-height: 1.75rem;
  width: 100%;
  display: flex;
  font-size: var(--font-16);
  color: var(--color-dark-blue-ds);
  align-items: center;
}

.btn-share-sidenav i {
  color: var(--color-primary-ds);
  font-size: var(--font-24);
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

/* Header pro */
.top-section {
  /*height: 6.0625rem;*/
  border-bottom: 1px solid var(--color-gray2-ds);
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1100;
}

.brand-space {
  width: 100%;
  min-width: 15.625rem;
  min-height: 4.625rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-gray2-ds);
}

.brand-space .content-brand {
  margin: 0 auto;
}

.brand-space .brand {
  height: 1.75rem;
  margin: 0 auto;
  margin-right: 3rem;
}

.content-menu-mobile {
  width: 3rem;
  display: flex;
  align-items: center;
  padding-left: 0.938rem;
}

.content-menu-mobile .icon-menu-hamb {
  font-size: var(--font-24);
  color: var(--color-primary-ds);
}

.content-titles-header {
  height: 3.875rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

.notification-icon {
  /* font-size: 1.2rem; */
  font-size: var(--font-20);
  color: var(--color-primary-ds);
}

.notification-container {
  background-color: #eb358c;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  right: -2px;
  top: -3px;
}

.notification-number {
  font-family: var(--font-bold);
  font-size: var(--font-8);
  line-height: var(--font-10);
  color: #ffffff;
}

/* Menu Pro */
.desktop-menu {
  max-width: 15.625rem;
  min-width: 15.625rem;
  height: 100vh;
}

.avatar {
  height: 3.625rem;
  width: 3.625rem;
}

.menu-option {
  width: 100%;
  border-radius: 24px;
  background-color: var(--color-white);
  overflow: hidden;
}

.menu-option-active {
  background-color: var(--color-light-ds);
}

.share-pro {
  font-size: var(--font-18);
  color: var(--color-primary-ds);
}

.btn-new-menu {
  border: none;
}

/*  */
.sidebar-menu {
  width: 15.625rem;
  min-width: 15.625rem;
  border-right: 1px solid var(--color-gray2-ds);
  height: calc(100vh - 8.563rem);
  margin-left: -15.625rem;
  display: flex;
  flex-direction: column;
  position: fixed;
  transition: all 0.4s linear;
  top: 8.563rem;
  z-index: 1091;
}

.sidebar-menu-active {
  margin-left: 0;
  position: fixed;
  z-index: 1100;
  top: 8.563rem;
}

.sidebar-menu-active ~ .overlay {
  background: rgba(40, 46, 85, 0.7);
  width: 100%;
  position: fixed;
  height: 100%;
  z-index: 1090;
}

.content-navegation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  margin-top: 8.563rem;
}

.content-scroll-navigation {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.content-scroll-navigation::-webkit-scrollbar {
  display: none;
}

.new-footer {
  width: 100%;
  margin-top: auto;
}

.logo-new-footer {
  width: 2rem;
  height: 2rem;
}

.card-session {
  border-bottom: 1px solid var(--color-gray2-ds);
}

.card-container {
  background-color: var(--color-white);
  padding: 1rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}
.register-form-container {
  background-color: var(--color-white);
  padding: 1rem 1.5rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 400px;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 25%);
}
.card-container .product-detail-new:nth-child(4n) {
  margin-right: 0.5rem !important;
}

.card-container-convenio {
  background-color: rgba(245, 193, 9, 0.4);
}

.card-container-convenio .product-detail-new {
  border-color: #f5c00e;
}

.card-container-convenio .content-detail-subscription {
  max-width: max-content;
  border: 1px solid #f5c10c;
  background-color: rgba(245, 192, 12, 0.15);
  padding-top: 0.3rem !important;
  padding-bottom: 0.3rem !important;
  margin-top: 0.5rem;
  margin-bottom: 0.7rem;
}

.card-container-convenio .approved-icon-sm {
  top: -4px;
}

.banner-img-share {
  width: 100%;
}

.container-pharmacies {
  background-color: var(--color-white);
  padding: 1rem 1rem 1.8rem 2rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}

.container-boxcard {
  background-color: var(--color-white);
  /*  padding: 0.5rem 1.25rem 0 0.5rem; */
  border-radius: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}
.container-results {
  background-color: var(--color-white);

  border-radius: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}
.content-number-text {
  display: flex;
  align-items: center;
}

.cirle-number {
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  background-color: var(--theme-color);
  border-radius: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-list-product-empty {
  border: 1px solid var(--theme-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  min-height: 3rem;
  padding: 1rem;
}

/*  */
.content-card-light {
  border-radius: 16px;
  background-color: var(--color-white);
  margin-bottom: 10px;
  padding: 16px;
}
.content-new-agreement {
  border-radius: 16px;
  margin-bottom: 10px;
  padding: 16px;
}

.banner-btn-convenio-more {
  background-color: #f5f6ff;
  padding: 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 96px;
}

.content-text-convenio-more {
  padding-left: 12px;
}

.card-btn-home-large {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d9deff;
  border-radius: 16px;
  min-height: 96px;
  padding: 16px;
  cursor: pointer;
  width: 100%;
}

.icon-card-btn-home-large {
  width: 32px;
  height: 32px;
}

.card-btn-home {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d9deff;
  border-radius: 16px;
  min-height: 80px;
  padding: 16px;
  cursor: pointer;
  width: 100%;
}

.icon-card-btn-home {
  width: 32px;
  height: 32px;
}

.btn-sidebar-share {
  display: flex;
  align-items: center;
}

.btn-sidebar-share i {
  font-size: var(--font-22);
}

.img-pilly {
  max-width: 112px;
  width: 100%;
}

.card-ms {
  width: 100%;
  margin-right: 16px;
  margin-bottom: 8px;
}

.content-msd-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content-msd-left {
  width: 100%;
}

.content-msd-right {
  max-width: 100%;
  width: 100%;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;
}

.contet-wrapper-bite {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content-card-bite {
  background-color: var(--color-white);
  border-radius: 0.5rem;
  border: 1px solid var(--color-gray2-ds);
  padding: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
}

.content-card-bite i {
  font-size: var(--font-30);
}

.content-light-wrapper {
  margin-bottom: 1rem;
}

.content-light-wrapper-header {
  height: 3.125rem;
  width: 100%;
  color: var(--color-white);
  background-color: var(--color-primary-ds);
  background-position: right top;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  padding: 1.375rem;
  border-radius: 16px 16px 0 0;
}

.content-light-wrapper-body {
  border-radius: 0 0 16px 16px;
  background-color: var(--color-white);
  padding: 1.375rem;
}

/* content-responsive- */
.content-responsive-1300 {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
}

.content-responsive-1021 {
  width: 100%;
  max-width: 1021px;
  margin: 0 auto;
  display: flex;
}

.content-responsive-926 {
  width: 100%;
  max-width: 926px;
  margin: 0 auto;
  display: flex;
}

.content-menu-pro-landing {
  flex-wrap: wrap;
  width: 100%;
  margin-top: 35px;
}

.card-lab {
  background-color: var(--color-white);
  border-radius: 5px;
  border: 1px solid var(--color-gray-5);
  padding: 12px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 20px 0px rgb(0 0 0 / 25%);
  height: 100%;
  cursor: pointer;
}
.card-lab:hover {
  border-color: #6578ff;
}
.labs-container {
  align-items: start;
  display: grid;
  gap: 20px;
  justify-content: center;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}

.menu-pro {
  /* width: calc(50% - 16px); */
  border-radius: 8px;
  border: 1px solid var(--color-gray-5);
  margin-right: 16px;
  margin-bottom: 16px;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  max-width: 160px;
  min-height: 100px;
  max-height: 100px;
  cursor: pointer;
}

.menu-pro:nth-child(even) {
  margin-right: 0;
}

.menu-pro p {
  max-width: 108px;
  margin: 0 auto;
  text-align: center;
}

.content-form-register-pro {
  background: rgb(255, 255, 255);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 100%;
  max-width: 380px;
}
.content-form-register-pro-spaces {
  padding: 46px 0 60px;
}

.maxw-390 {
  width: 100%;
  max-width: 390px;
}

.section-two,
.section-four {
  /* background: #ffffff; */
  padding: 36px 0 22px;
}

.icon-delete-btn {
  position: absolute;
  right: 0;
  z-index: 2000;
}

.power-by-yapp {
  max-height: 36px;
}
@media (max-width: 767px) {
  .container-pharmacies {
    width: 100vw !important;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    background-color: var(--color-white);
    /* padding: 1rem 1.25rem 1rem 1.25rem; */
    padding-top: 24px;
    padding-bottom: 16px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 1rem;
    border-radius: 0px;
  }

  /* .container-results {
    display: flex;
  justify-content: center;
  align-items: center;
  } */
  .txt-title {
    font-size: var(--font-20) !important;
  }

  .title-big {
    font-size: var(--font-16) !important;
  }

  .title-quotation {
    font-size: var(--font-18) !important;
  }

  .title-primary--xxl {
    font-size: var(--font-16) !important;
  }
}
@media (min-width: 768px) {
  .banner-img-share {
    max-height: 100%;
  }
  .sidebar-menu {
    transition: none;
  }
  .card-btn-home {
    width: calc(50% - 4px);
    margin-bottom: 8px !important;
  }
  .card-btn-home:nth-child(2) {
    margin-right: 0 !important;
  }
  .content-msd-right {
    border-left: 1px solid var(--color-gray2-ds);
  }
  .content-msd-wrapper {
    flex-direction: row;
  }
  .content-msd-right {
    padding-left: 1.75rem;
    padding-right: 0.5rem;
  }
  .contet-wrapper-bite {
    flex-direction: row;
    align-items: center;
    align-items: stretch;
  }
  .content-space-horizontal-bite {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
  .card-ms {
    width: calc(50% - 16px);
    margin-right: 16px;
    margin-bottom: 8px;
  }

  .container-pharmacies {
    padding-top: 26px;
    padding-left: 24px;
  }

  .content-titles-header {
    padding-left: 3rem;
  }
  .content-menu-mobile {
    padding-left: 3rem;
  }
}

@media (min-width: 992px) {
  .sidebar-menu {
    position: relative;
    margin-left: 0;
    height: calc(100vh - 6.0625rem);
    top: 0;
    transition: none;
  }
  .sidebar-menu-active {
    position: relative;
    top: 0;
  }
  .sidebar-menu-active ~ .overlay {
    display: none;
  }
  .top-section {
    height: 6.0625rem;
    display: flex;
    align-items: center;
    position: relative;
  }
  .content-menu-mobile {
    display: none;
  }
  .brand-space {
    max-width: 15.625rem;
    height: 6.0625rem;
    border: 0;
    border-right: 1px solid var(--color-gray2-ds);
  }
  .brand-space .brand {
    height: 2.25rem;
  }
  .content-titles-header {
    width: 100%;
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .content-navegation {
    margin-top: 0;
    height: calc(100vh - 6.0625rem);
  }
  .card-container .product-detail-new {
    max-width: 10.29rem;
    width: 100%;
  }

  .content-scroll-navigation::-webkit-scrollbar {
    display: block;
  }

  .content-scroll-navigation .content-wrap {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .card-btn-home {
    width: calc(33% - 0.206rem);
    margin-bottom: inherit;
  }
  .card-btn-home:nth-child(2) {
    margin-right: 8px !important;
  }
  .content-msd-right {
    max-width: 18.75rem;
    padding-left: 1.75rem;
    padding-right: 0.5rem;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 25rem;
  }
  .content-space-horizontal-bite {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .container-pharmacies {
    padding-top: 32px;
    /* padding-left: 32px; */
  }
}
