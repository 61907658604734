.sidenav-menu {
  padding: 10px;
  position: absolute;
}

.sidenav-menu i {
  font-size: var(--font-24);
  color: var(--color-primary);
}

.content-sidenav {
  background: rgba(40, 46, 85, 0.9);
  position: absolute;
  height: 100vh;
  width: 100%;
  max-width: 420px;
  z-index: 1060;
  transition: all .3s linear;
  margin-left: -100%;
}

.is-open {
  margin-left: 0;
}

.content-sidenav .lightbox {
  background: rgba(40, 46, 85, 0.5);
  content: '';
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 0;
}

.is-show {
  opacity: 1 !important;
}

.inner-sidenav {
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 1050;
  padding: 64px 0 30px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: 'flex-start';
}

.btn-close {
  border: transparent;
  background-color: transparent;
  width: 48px;
  height: 48px;
  padding: 5px;
  position: absolute;
  right: 0px;
  top: 0px;
}

.btn-close i {
  color: var(--color-white);
  font-size: var(--font-20);
}

.tag-sidenav {
  font-size: var(--font-12);
  font-family: var(--font-bold);
  color: var(--color-white);
  background-color: var(--color-primary);
  padding: 2px 10px;
  border-radius: 20px;
}

.comming-soon {
  background-color: var(--color-gray-4);
}

.items-sidenav {
  padding-bottom: 16px;
  padding-top: 16px;
  border-bottom: 1px solid var(--color-white);
  border-top: 1px solid var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.items-sidenav:nth-child(+n) {
  border-bottom: 0px solid var(--color-white);
}

.items-sidenav:nth-last-child(2) {
  border-bottom: 1px solid var(--color-white);
}

.content-scroll-sidenav {
  padding: 0 16px;
  overflow-y: auto;
  overflow-x: hidden;
}

@media (min-width: 420px) {
  .content-scroll-sidenav {
    padding: 0 51px;
  }
}