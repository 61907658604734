.btn-sidenav-explore {
  width: 100%;
  font-size: var(--font-16);
  font-weight: 400;
  display: flex;
  color: var(--color-dark-blue-ds);
  align-items: center;
  background: transparent;
  margin: 0;
  padding: 0;
  padding-left: 0.75rem;
  min-height: 2rem;
}

.btn-sidenav-explore i {
  color: var(--theme-color);
  font-size: var(--font-16);
  margin-left: 0;
  margin-right: 0.5rem;
  padding: 0;
}

.btn-sidenav-active {
  background-color: #f4f6ff;
  color: var(--theme-color);
  border-radius: 1.875rem;
  min-height: 2rem;
}

/* Accordion */
.sidenav-accordion {
  padding-left: 0.75rem;
  padding-right: 0;
  margin-bottom: 1rem;
}

.sidenav-accordion .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0 rem;
}

.sidenav-accordion .card-header {
  padding: 0 !important;
  margin-bottom: 0 !important;
  background-color: transparent;
  border-bottom: 0px solid var(--color-gray2-ds);
}

.sidenav-accordion .card-body {
  margin: 0 !important;
  padding: 0 !important;
}

.header-sidenav {
  padding-left: 0;
  padding-right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-dark-blue-ds);
  cursor: pointer;
  margin-bottom: 0;
}

.icon-sidenav-header {
  font-size: var(--font-24);
  margin-left: 0;
  margin-right: 0.5rem;
  color: var(--theme-color);
}

.contente-left-inner-sidenav {
  padding-left: 0;
}

/* .contente-left-inner-sidenav .btn-submenu-sidenav {
  margin-top: 1rem !important;
} */

.btn-submenu-sidenav {
  padding-top: 0;
  padding-left: 34px;
  display: flex;
  align-items: center;
  min-height: 32px !important;
}
