.tutorial-item {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.5rem 1.5rem;
    width: 12rem;
}

.img-container {
    width: 12rem;
}

.tutorial-item-tablet {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3.5rem 0.5rem;
    width: 12rem;
}

.tutorial-item-mobile {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.5rem;
    width: 12rem;
}

.tutorial-item, .tutorial-item-mobile, .tutorial-item-tablet .figure {
    width: 100%;
    text-align: center;
}

.tutorial-item, .tutorial-item-mobile .figure img {
    margin-bottom: 0.5rem;
}

.ellipse {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-regular);
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-12);
    line-height: 16px;
    color: #ffffff;
    background-color: var(--theme-color);
}

.tutorial-item, .tutorial-item-mobile, .tutorial-item-tablet .figure figcaption {
    width: 12rem;
    line-height: 19px;
}

.caption {
    margin-top: 200px;
    margin-left: 5px;
    /* font-family: var(--font-regular); */
    color: #000;
    font-weight: 600;
    font-size: var(--font-14);
}