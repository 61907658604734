.content-responsive html body {
  background-color: transparent;
}

html body #presc_container {
  font-family: var(--font-regular);
  background-color: #FFFAFA;
}

html body #presc_container .card  {
  border: none !important;
  background-color: transparent;
}
html body #presc_container .card  .card-body .card-title{
  background-color: transparent;
  border: none !important;
}

.card-subtitle {
  background-color: transparent !important;
  border: none !important;
  border-top: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

#tdrugs_container {
  border: none !important;
}

#tdrugs_container .drugnumber {
  display: block !important;
}

#presc_list {
  background-color: transparent !important;
}

.list-group-item {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important ;
}

.content-scroll-navigation .flex-grow .content-responsive html body #presc_container .container-fluid {
  background-color: transparent;
}

.content-scroll-navigation .flex-grow .content-responsive html body #presc_container .container-fluid .row {
  background-color: transparent;
}


#presc_title {
  font-size: var(--font-14) !important;
  background-color: inherit !important;
}

.alert_section {
  display: block !important;

  padding: 20px 0;
}