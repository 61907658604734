.nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: none;
  border-bottom: 3px solid var(--color-primary);
  color: var(--color-primary);
  background-color: transparent;
  text-align: center;
  white-space: nowrap;
}
.nav-tabs .nav-link {
  position: relative;
  bottom: -3px;
  border-bottom: 3px solid transparent;
  font-family: var(--font-bold);
  color: var(--color-disabled);
  background-color: transparent;
  margin: 0px;
  text-align: center;
  white-space: nowrap;
}

.link:hover,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-top: none;
  border-right: none;
  border-left: none;
  white-space: nowrap;
}

.tabs {
  width: 100%;

}

.nav-tabs .nav-link:nth-child(1) {
  width: 30%;

}
.nav-tabs .nav-link:nth-child(2) {
  width: 70%;

}
