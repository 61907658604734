/* Accordion Pro */
.content-card-info-pro {
  background: var(--color-white);
  border: 1px solid var(--color-gray-5);
  border-radius: 8px;
  margin-bottom: 24px;
}
.content-card-info-pro .toggle-default {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.content-card-info-pro .toggle-default .card,
.content-card-info-pro .toggle-default .card-header {
  background-color: transparent !important;
  border-top: none !important;
  border-bottom: none !important;
}

.content-card-info-pro .line-gray .accordion {
  margin-top: -2px;
  border-bottom: 0px solid var(--color-gray-5) !important;
}

.line-gray .accordion:last-child .card {
  border-bottom: 1px solid var(--color-gray-5) !important;
}

.line-gray .accordion .card:nth-last-child(1) {
  border-bottom: 0px solid var(--color-gray-5) !important;
}

.content-card-info-pro .toggle-default .card-body {
  padding: 0 20px 18px !important;
}

/* new release */

.content-card-info-pro-new-release .toggle-default .card,
.content-card-info-pro-new-release .toggle-default .card-header {
  background-color: transparent !important;
  border-top: none !important;
  border-bottom: none !important;
}

/* Accordion New Relase */

.content-card-info-pro-new-release .line-gray .accordion {
  margin-top: -2px;
  border-bottom: 0px solid var(--color-gray-5) !important;
}

.line-gray .accordion:last-child .card {
  border-bottom: 1px solid var(--color-gray-5) !important;
}

.content-card-info-pro-new-release .toggle-default .card-body {
  padding: 0 20px 18px !important;
}
@media (min-width: 768px) {
  .content-card-info-pro-new-release .toggle-default .card-body.inner-padding {
    padding: 0 0px 18px !important;
  }
  .content-card-info-pro .toggle-default .card-body.inner-padding {
    padding: 0 0px 18px !important;
  }
  .content-card-info-pro-new-release
    .toggle-default
    .accordion:last-child
    .card:last-child {
    border-bottom: 1px solid #c9cfff !important;
  }
  .content-card-info-pro-new-release .toggle-default {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
