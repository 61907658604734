@font-face {

  font-family: 'icomoon';

  src: url('./icomoon.eot?elbh9y');

  src: url('./icomoon.eot?elbh9y#iefix') format('embedded-opentype'),

    url('./icomoon.ttf?elbh9y') format('truetype'),

    url('./icomoon.woff?elbh9y') format('woff'),

    url('./icomoon.svg?elbh9y#icomoon') format('svg');

  font-weight: normal;

  font-style: normal;

  font-display: block;

}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-medical-home:before {
  content: "\e938";
  color: #6578ff;
}
.icon-buy-medicaments:before {
  content: "\e939";
  color: #6578ff;
}
.icon-circle-x:before {
  content: "\e900";
}
.icon-paid:before {
  content: "\e935";
}
.icon-pay:before {
  content: "\e93b";
}
.icon-perfil:before {
  content: "\e934";
}
.icon-healt:before {
  content: "\e933";
}
.icon-search-2:before {
  content: "\e931";
}
.icon-yapp-moon:before {
  content: "\e932";
}
.icon-share-2:before {
  content: "\e930";
}
.icon-bell:before {
  content: "\e92f";
}
.icon-info-2:before {
  content: "\e92e";
}
.icon-whatsapp-circle:before {
  content: "\e923";
}
.icon-email:before {
  content: "\e924";
}
.icon-more:before {
  content: "\e925";
}
.icon-facebook-circle:before {
  content: "\e926";
}
.icon-eye-off:before {
  content: "\e920";
}
.icon-eye-on:before {
  content: "\e921";
}
.icon-alert:before {
  content: "\e922";
}
.icon-send-2:before {
  content: "\e91e";
}
.icon-download:before {
  content: "\e91f";
}
.icon-menu-hamb:before {
  content: "\e91d";
}
.icon-close:before {
  content: "\e91c";
}
.icon-share:before {
  content: "\e91a";
}
.icon-info:before {
  content: "\e91b";
}
.icon-send-pro:before {
  content: "\e918";
}
.icon-phone-pro:before {
  content: "\e919";
}
.icon-dots-separation:before {
  content: "\e917";
}
.icon-pharmacy-check:before {
  content: "\e916";
}
.icon-delivery:before {
  content: "\e915";
}
.icon-wallet:before {
  content: "\e911";
}
.icon-home-check:before {
  content: "\e912";
}
.icon-box-check:before {
  content: "\e913";
}
.icon-store-check:before {
  content: "\e914";
}
.icon-checkmark:before {
  content: "\e910";
}
.icon-instagram:before {
  content: "\e90b";
}
.icon-youtube:before {
  content: "\e90c";
}
.icon-twitter:before {
  content: "\e90d";
}
.icon-linkedin:before {
  content: "\e90e";
}
.icon-facebook:before {
  content: "\e90f";
}
.icon-chevron-left:before {
  content: "\e906";
}
.icon-chevron-right:before {
  content: "\e937";
}
.icon-chevron-down:before {
  content: "\e90a";
}
.icon-address:before {
  content: "\e905";
}
.icon-coments:before {
  content: "\e909";
}
.icon-search:before {
  content: "\e901";
}
.icon-check:before {
  content: "\e902";
}
.icon-send:before {
  content: "\e903";
}
.icon-phone:before {
  content: "\e904";
}
.icon-calendar:before {
  content: "\e907";
}
.icon-arrow-back:before {
  content: "\e908";
}
.icon-delete:before {
  content: "\e927";
}
.icon-flag-pharmacy:before {
  content: "\e929";
}
.icon-location:before {
  content: "\e928";
}
.icon-circle-price:before {
  content: "\e92a";
}
.icon-circle-plus:before {
  content: "\e92b";
}
.icon-circle-time:before {
  content: "\e92c";
}
.icon-chevron-arrow-back:before {
  content: "\e92d";
}
.icon-circle-check:before {
  content: "\e936";
}
.icon-pen:before {
  content: "\e93d";
}
