.step-tracker-content {
  position: relative;
  counter-reset: section;
  max-width: 300px;
  margin: 45px auto 0;
}

.step-tracker {
  list-style: none;
}

.step-tracker-item {
  display: flex;
  align-items: flex-start;
  padding-bottom: 40px;
  position: relative;
}

.step-track--by-step {
  width: 20px;
  height: 19px;
  background-color: var(--color-gray-4);
  border-radius: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-top: 4px;
  padding: 0;
}

.step-track--by-step::before {
  counter-increment: section;
  content: '' counter(section);
  font-family: var(--font-regular);
  color: var(--color-white);
  font-size: var(--font-12);
}

.step-track--description {
  font-size: var(--font-14);
  color: var(--color-gray-5);
  display: flex;
  align-items: flex-start;
  line-height: 1.5rem;
}

.step-track--description i {
  font-size: var(--font-25);
  margin-right: 15px;
}

.step-track--separation {
  --top: 30px;
  position: absolute;
  width: 3px;
  height: calc(100% - 35px);
  bottom: 70px;
  left: 8px;
}

.step-track--separation::after, 
.step-track--separation::before {
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background-color: var(--color-gray-4);
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.step-track--separation::after {
  bottom: 0;
}

.step-track--separation::before {
  top: 0;
}

.step-track--separation .middle::after, 
.step-track--separation .middle::before {
  width: 3px;
  height: 3px;
  border-radius: 3px;
  background-color: var(--color-gray-4);
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.step-track--separation .middle::after {
  bottom: 24%;
}

.step-track--separation .middle::before {
  top: 24%;
}

.step-track--separation .center::after, 
.step-track--separation .center::before {
  width: 2px;
  height: 2px;
  border-radius: 2px;
  background-color: var(--color-gray-4);
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.step-track--separation .center::after {
  bottom: 40%;
}

.step-track--separation .center::before {
  top: 40%;
}

.step-tracker-item:first-child .step-track--separation {
  display: none;
}

.step-tracker .active .step-track--description {
  color: var(--color-primary);
}

.step-tracker .active .step-track--separation::after, 
.step-tracker .active .step-track--separation::before, 
.step-tracker .active .step-track--separation .middle::after, 
.step-tracker .active .step-track--separation .middle::before, 
.step-tracker .active .step-track--separation .center::after, 
.step-tracker .active .step-track--separation .center::before {
  background-color: var(--color-primary);
}

.step-tracker .active .step-track--by-step {
  background-color: var(--color-primary);
}

.step-track--by-step-active::after{
  content: '';
  width: 26px;
  height: 27px;
  border-radius: 26px;
  position: absolute;
  border: 2px solid var(--color-primary);
  top: -4px;
  left: -4px;
}

.step-tracker .active .step-track--by-step::before {
  content: '\e910';
  font-family: 'icomoon';
  padding: 0px 2px;
}
